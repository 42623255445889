<template>
<div class="cont">
<Header />

<div class="About">

<div class="row write animate__animated animate__zoomIn">

<h4 class="sectionheader">About Us</h4>


Considering the global competition for jobs, the need for a graduate to stand out from the crowd cannot be over emphasized. Chemical Engineering is a profession that provides solution to problems. Chemical Engineering in general is concerned with industrial processing of natural raw materials into finished products of enhanced industrial and commercial values through the application of basic science. It is one of the most broadly based engineering disciplines, creating high demand for Chemical Engineers across a variety of industries and professions.
Chemical Engineering is concerned with identifying suitable raw materials and processes for their exploitation, development and conversion into immediate and useful products through application of basic science.
Our goal is to provide the adequate educational training and skills necessary for understanding, planning, designing, operating and maintaining the various processes and operations involved in the modern chemical industry in our students. Students at this Department of Chemical Engineering will be equipped to take lead responsibilities in the manufacturing, exploration and energy generation sector.
Final year undergraduate students in this department will work on relevant real life problems as projects and such projects will be tailored to specific needs in the society. The purpose for this training approach is to produce graduates who will be problem solvers and consequently create jobs instead of quailing jobs.</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------------ -->
<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">Our Vision</h4>
Our vision is to establish strong collaborations with universities within the country and the Western World for the pin
 pose of exchanging our research findings and thereby propagating the name of our great university, MOUAU
</div>


<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">Our Mission</h4>
The mission of the Department of Chemical Engineering, MOUAU is to produce world class Chemical Engineers who will be solution providers in their respective organizations.
</div>


<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">Our Goals</h4>
our main gola is to provide the adequate educational training and skills necessary for understanding, planning, designing, operating and maintaining the various processes and operations 
involved in the modern chemical industry in our students.
</div>




<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">AIMS AND OBJECTIVES</h4>
The aim of establishing the Department of Chemical Engineering is to bridge the gap between research in the academia and the industry. There are so many challenges facing the industry and the technical know-how to face the challenges is paramount. We are out to provide the technical know-how and to chart the course for an industrial revolution.
The objective of this newly established department is not only to train students for conventional jobs but also to adequately equip students for the real world outside the university in order for them to be real innovators, inventors and entrepreneurs.
The graduates from the Department of Chemical Engineering, MOUAU, should be able to compete with other graduates of Chemical Engineering anywhere in the world. The department will take advantage of the collaborations between MOUAU and other universities in Europe and America to further train the students. Students will be encouraged to get relevant experience in related companies/ institutions.
Internigeria and result-oriented researches will he carried out by the undergraduate students of this department. Every 4th year student will have a mentor in a university/ research instution in preparation for the final Bachelor Thesis. All theses to be taken at the department must be result driven and tailored to the specific needs of the society. As the Head of the Chemical Engineering Department, I will make sure that the graduates from this department are highly sort after due to the high quality of training they will undergo.

</div>




<!-- ------------------------------------------------------------------------------------------------------------------------------------ -->


<div class="row write animate__animated animate__zoomIn">

<h4 class="sectionheader">A Brief History About Chemical EngineeriNg MOUAU</h4>

Michael Okpara University of Agriculture, Umudike was established in May, 1993, by Edict No. 48 of the Federal Government of Nigeria.
 Although the school was established with one of its primary focus being to provide quality trainings for students for the purpose of agricultural development and improved techniques for better 
 agricultural yields for I he sustenance of the nation; it was  soon  obvious  that this will be impossible  without  a complementary framework. Consequently, Dr. O. Onuba  in 2001/2002 academic session, introduced the College of Engineering and Engineering Technology   into  the   University's   existing   colligate   system   with   
the establishment of the Department of Agricultural Engineering followed by the Department  of Civil Engineering.  Mechanical  Engineering  
and Electrical/Electronics Engineering   in   the   2003/2004  academic session. Chemical Engineering was introduced in the 2011/2012 academic session. 
All of these departments run a 5-year degree programme which culminates into the award of the Bachelor of Engineering (B.Eng) degree. Prof. Adekunle Kayode was the first
 (Head of Department of Chemical Department MOUAU).
</div>


<Resmenu />

</div><!--- home end-->


<Footer />
</div>

</template>

<script>
//import $ from "jquery"

import Header from './plugins/header.vue'
import Footer from './plugins/Footer.vue'
import Resmenu from './plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
name: 'HomePage',
components: {
Header,
Footer,
Resmenu

},
mounted() {AOS.init()}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
body{overflow: hidden;}
.sectionheader{color:#008a31;text-transform: uppercase;font-weight: bold;margin-bottom: 10px;margin-left: -10px;text-align: center;}
.sectionheadersub{color: black;text-transform: uppercase;font-weight: bold;margin-bottom: 10px;font-size: 17px;margin-left: -10px;}

.row{margin-bottom: 40px;font-size: 16px;text-align: justify;}

.About{padding: 50px;overflow: hidden;margin-bottom:50px ;margin-top: 40px;}
.main{font-size: 18px;color:white;text-align: justify;}



@media screen and (max-width:480px){
.home{padding: 20px;padding-bottom: 0px;margin-top: 120px;}
.sectionheader{margin-bottom: 20px;}
/* .main{font-size: 14px;text-align: justify;padding: 4px;} */
.About{padding: 30px;overflow: hidden;margin-bottom:20px ;margin-top: 40px;}
.row{font-size: 15px;text-align: justify;}

/* .write{padding: 10px;padding-top: 0px;font-size: 14px;text-align: LEFT;} */

}
</style>
