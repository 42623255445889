<template >
<div class="cont"> 
<Header/>
<div class="row sliderow">
<Slide />
</div>

<div id="about">
<div class="row">
<div class="col-md-1"></div>

<div class="col-md-10" data-aos="zoom-in">
<div class="sectionheader sectionheaderabout" data-aos="zoom-in">About Us</div>  
Considering the global competition for jobs, the need for a graduate to stand out from the crowd cannot be over emphasized. Chemical Engineering is a profession that provides solution to problems. Chemical Engineering in general is concerned with industrial processing of natural raw materials into finished products of enhanced industrial and commercial values through the application of basic science. It is one of the most broadly based engineering disciplines, creating high demand for Chemical Engineers across a variety of industries and professions.
Chemical Engineering is concerned with identifying suitable raw materials and processes for their exploitation, development and conversion into immediate and useful products through application of basic science.

<router-link to="/About" class="universal_btn">Learn More</router-link>
</div>
<div class="col-md-1"></div>
</div>
</div>

<!-- our mission vission and goals -->

<div id="MVG">
<div class="row">
<div class="col-md-4">
<div class="statementconts" data-aos="zoom-in">
<div class="sectionheader2 gh">Our Vision</div>  
Our vision is to establish strong collaborations with universities within the country and the Western World for the pin pose of exchanging our 
research findings and thereby propagating the name of our great university, MOUAU.</div>
</div>

<div class="col-md-4">
<div class="statementconts" data-aos="zoom-in">
<div class="sectionheader2 gh">Our Mission</div>  
The mission of the Department of Chemical Engineering, MOUAU is to produce world class Chemical Engineers who will be solution providers in their respective organizations.
</div>
</div>


<div class="col-md-4">
<div class="statementconts goalcont" data-aos="zoom-in">
<div class="sectionheader2 gh">Our Goal</div>  
our main gola is to provide the adequate educational training and skills necessary for understanding, planning, designing, operating and maintaining the various processes and operations 
involved in the modern chemical industry in our students.</div>
</div>
</div></div>


<!-- membership -->

<br>
<div id="membership" data-aos="slide-up">
<div class="sectionheader msec" data-aos="zoom-in">Philosophy of chemical enginerring department</div>
<!-- <div class="whois" data-aos="fade-in">who is an eligible nsche member?</div> -->
<div class="row">
<div class="col-md-8" data-aos="zoom-in">
Engineering is the profession that converts scientific knowledge and ideas of inventors into practical devices, machines and structures.
The curriculum is designed to prepare Chemical Engineering graduates for I nil her education and personal development through their entire professional carrier.
 We strive to accomplish these goals by providing a rigorous and demanding curriculum that incorporates lectures, discussions, laboratory practice and project development 
 experiences in basic sciences, mathematics, engineering sciences, and design as well as the humanities and social sciences. 
</div>
<div class="col-md-4">
<router-link to="/About" class="join_btn" data-aos="fade-in">Learn More</router-link>
</div>
</div></div>


<div class="admissions" data-aos="zoom-in">
   <div class="row">
      <div class="sectionheader">Admissions</div>
<div class="col-md-5">
   <img src="../assets/admission.png" alt="" class="ad_image big_ad" data-aos="zoom-in">
<router-link to="/admission" class="ad_sub_heads ad_sub_heads1">UTME Entry</router-link>
<router-link to="/admission" class="ad_sub_heads">Direct Entry</router-link>
<router-link to="/registration" class="ad_sub_heads ad_sub_heads3">Registration and matriculation</router-link>

</div>
<div class="col-md-7 adwriteup THISWRITE" data-aos="zoom-in">
The Department offers a 5-year programme for the Bachelor of Engineering (B.Eng) Honours Degree in Chemical Engineering. The admission requirements for UTME candidates into the course is a minimum of 5 (ordinary Level) credit pass in West Africa  Senior Secondary School Certificate Examination  (WASSCE),  General  Certificate   of  Education (GCE) or Nigerian Examination Council Ordinary Level (NECO) in not more than two  sittings.  The credits  must  include  English Language,
 Mathematics, Physics, and Chemistry. The fifth credit should be any of Biology, Further Mathematics or Agricultural Science. The candidate must not be less than 16 years of age. He or she must have a UTME score that meets the Departmental cut-off mark determined by the University and Post-UTME screening test.
<router-link to="/admission" class="ad_more">Go to page</router-link>

</div>

</div>
</div>



<div class="admissions" data-aos="fade-in">
<div class="sectionheader text-right" data-aos="fade-in">Our Graduates</div>
   <div class="row" data-aos="fade-in">
<div class="col-md-8 adwriteup graduatewriteup">
 <div class="demostrate"> Chemical Engineering graduates will demonstrate</div> 
<ol>
   <li>An ability to apply knowledge of mathematics, science and engineering.</li>
   <li>An ability to design and conduct experiments, as well as to analyze and interpret data.</li>
   <li>An ability to design a system, component, or process to meet desired needs within realistic constraints such as economic, environmental,  social,  political,  ethical,   health,  safety   and sustainability.
</li>
   <li>An ability to function on multidisciplinary.</li>
   <li>An ability to identify, formulate, and solve engineering problems.</li>
   <li>An understanding of professional and ethical responsibility.</li>
   <li>An ability to communicate effectively.</li>
   <li>An broad education necessary to understand the impact of engineering solutions in a global, economic, environmental, and social context.</li>
   <li>A recognition of the need for, and an ability to engage in life-long learning.</li>
   <li>A knowledge of contemporary issues .</li>
   <li>A ability to use the techniques, skills, and modem engineering tools necessary for engineering pra.</li>


</ol>
</div>

<div class="col-md-4">
   <img src="../assets/grad.png" alt="" class="ad_image ad2">
<div class="thru">
   Through creative application of these Chemical Engineering principles, Chemical Engineering graduates will be able to create innovative solutions to important industrial and societal problems in areas such as development of clean energy sources, advancement of life sciences, production of Pharmaceuticals, 
   sustainable systems, responsible environmental stewardship, discovery and production of new materials.
</div>

</div>

</div>
</div>


<!-- chemical engineering lecturers-->

<br>
<div id="lecturers">
<div class="sectionheader leadhead"  data-aos="zoom-in">chemical engineering lecturers</div>
<div class="row">
<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard">
<img src="../assets/excos/VP.png" alt="Precious Kalu" class="excoimg lect_img">

<div class="exconame">Precious Kalu <span class="exco_position"> Emeritus HOD </span> </div>

</div></div>


<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard"> 
<img src="../assets/excos/president.jpg" alt="Mba Uchenna E" class="excoimg excoimgbig lect_img lectimgbig">

<div class="exconame">Emeruwa C. Wisdom<span class="exco_position"> Head of department</span> </div>

</div></div>


<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard">
<img src="../assets/excos/secretary.jpeg" alt="nigeria Congress" class="excoimg lect_img">

<div class="exconame">Ukonu George C <span class="exco_position"> Secretary General </span> </div>

</div></div>

</div>
<br>
<router-link to="/Executives" class="allexcos"><b>View All</b></router-link>

</div>






<div id="support" data-aos="slide-up">
<div class="sectionheader msec">Support Us now</div>
<div class="row">
<div class="col-md-7">
   Your support is vital to the success of the Nigerian society of chemical engineering MOUAU. Your semester dues will 
   go a long way to facilitate activities of NSChE. Thank you for your generosity and commitment to our Association.
   <br>
   <br>
<div class="deptacct">Bank - Access Bank<br>Account Name - NSChE MOUAU<br> Account Number - 1469609555.</div>   

</div>
<div class="col-md-5">
<div class="forms">
<div class="inputcont">
<span class="lebel">Amount</span>
<input type="text" placeholder="0.00 NGN" class="acctno input" >

<select type="text" placeholder="select">
<option value="select" disabled>Select a donation method</option>
<option value="Paystack"  selected>Paystack</option>
<option value="FlutterWave">FlutterWave</option>
<option value="Bank Account">Bank account</option>
</select>
</div>
<br>
<br>
<div class="pay">Pay Now</div>

</div>
</div>
</div></div>






<!-- leadership -->

<br>
<div id="leader">
<div class="sectionheader leadhead"  data-aos="zoom-in">NSChE leadership</div>
<div class="row">
<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard">
<img src="../assets/excos/VP.png" alt="Precious Kalu" class="excoimg">

<div class="exconame">Johnson Chidinma F.<span class="exco_position"> Vice President </span> </div>

</div></div>


<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard"> 
<img src="../assets/excos/president.jpg" alt="Mba Uchenna E" class="excoimg excoimgbig">

<div class="exconame">Emeruwa C. Wisdom<span class="exco_position"> President</span> </div>

</div></div>


<div class="col-md-4" data-aos="zoom-in">
<div class="leadersCard">
<img src="../assets/excos/secretary.jpeg" alt="nigeria Congress" class="excoimg">

<div class="exconame">Ukonu George C <span class="exco_position"> Secretary General </span> </div>

</div></div>

</div>
<br>
<router-link to="/Executives" class="allexcos"><b>View All</b></router-link>

</div>
<br>
<Resmenu />

<br>
   <div class="mark">
<div class="marks" data-aos="zoom-in"><span class="figure count1">1280</span><span class="figure">+</span><br>Admission</div>
<div class="marks" data-aos="zoom-in"><span class="figure count2">1600</span><span class="figure">+</span><br>Graduates</div>
<div class="marks" data-aos="zoom-in"><span class="figure count3">14</span><br>Staffs</div>
<div class="marks" data-aos="zoom-in"><span class="figure count4">560</span><br>Students</div>
<br>
<br>

</div>
<div class="footer row footrow">
<Footer />

</div>

</div>





</template>


<script>

import Footer from './plugins/Footer.vue'
import Slide from './plugins/slide.vue'
import Header from './plugins/header.vue'
import Resmenu from './plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'

import anime from 'animejs'

export default {
name: 'Home',
components: {
Header,
Slide,
Footer,
Resmenu
}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

anime({
targets :'.count1',
innerHTML:[0,2400],
easing:'easeOutBounce',
round:1,
duration:10000
})

anime({
targets :'.count2',
innerHTML:[0,1600],
easing:'easeOutBounce',
round:1,
duration:10000
})

anime({
targets :'.count3',
innerHTML:[0,14],
easing:'easeOutBounce',
round:1,
duration:10000
})

anime({
targets :'.count4',
innerHTML:[0,560],
easing:'easeOutBounce',
round:1,
duration:10000
})

}


}

</script>

<style scoped>

/* .sliderow,.footrow{width: 50%;} */
/* *{overflow-x: hidden;clear: both;} */
/* #570903 */
.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
#about{padding: 0px;padding-top: 40px;padding-bottom: 0px;font-size: 20px;text-align: justify;}
#membership{padding: 100px;padding-top: 60px;padding-bottom: 60px;font-size: 16px;margin-top: 20px;
background-color: #008a31;color: white;text-align: justify;}

.sectionheader{color: #008a31;font-size: 22px;text-transform: uppercase;font-weight: bolder;margin-bottom: 10px;}
.sectionheaderabout{color: #008a31;}
.msec{color:white;font-weight: bolder;margin-bottom: 10px;}
.universal_btn{width: 200px;padding: 7px;margin-left: 0px;margin-right: 20px;display: block;border:none ;padding-top: 9px;margin-top: 20px;
background-color:#008a31;color:white; text-align: center;font-size: 17px;border-radius:0px;text-decoration: none;height: 45px;font-weight: bold;
transition: background-color 2s;
transition-timing-function: ease;
}

.universal_btn:hover{background-color: yellow;}

.whois{color: white;font: 14px;text-transform: uppercase;margin-bottom: 30px;}

#MVG{padding: 150px;padding-top: 100px;padding-bottom: 100px;background-color: white;margin-top: 40px;margin-bottom: 40px;}
.sectionheader2{color:#008a31;font-size: 18px;text-transform: uppercase;}
.statementconts{padding: 20px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);border-radius: 12px;transition: background-color 1s, color 1s;
transition-timing-function: ease;
height: 250px;width: 100%;font-size: 16px;;}
.statementconts:hover{background-color:black;color: white;}
.statementconts:hover .sectionheader2{color: yellow;}

.gh{font-weight: bold;}
.join_btn{width: 250px;padding: 7px;margin-left:20px;margin-right: 0px;display: block;border:none ;padding-top: 15px;margin-top: 50px;
background-color:rgb(255, 255, 0);color:black; text-align: center;font-size: 17px;border-radius:4px;text-decoration: none;height:60px;font-weight: bold;
transition: background-color 2s, border-radius 2s;
transition-timing-function: ease;
}

.join_btn:hover{color: #008a31;background-color: white;border-radius: 5px;}


.sectionheaderevent{color: #008a31;}
#events,#leader{padding: 140px;padding-top: 100px;padding-bottom: 0px;text-align: center;}
/* #events{background-color: rgba(252, 212, 212, 0.83);padding-bottom: 100px;} */
/* .eventsCard{height: 220px;width: 100%;margin-top: 40px;}
.eventimg{width: 100%;height: 100%;transition: transform .2s;}
.eventsfixed{height: 220px;width: 315px;position: absolute ;background-color: #570903d8;margin-bottom: 100px;
transition: background-color 2s;
transition-timing-function: ease;}
.eventsfixed:hover{background-color: rgba(0, 0, 0, 0.827);}
.eventwriteup{text-align: left;padding: 10px;color: white;margin-top: 40px;text-align: center;}
.eventname{text-transform: uppercase;font-size: 20px;}
.eventsfixed3{background-color: #570903be}
.eventsfixed2{background-color: #0072b8b1}
.eventsfixed1{background-color: #f8080081}


.gotogallery{width: 80%;padding: 7px;margin-left:10%;margin-right: 0px;display: block;border:2px solid #f80800 ;padding-top: 8px;margin-top: 10px;
background-color: transparent;color:white; text-align: center;font-size: 17px;border-radius:0px;text-decoration: none;height:40px;font-weight: bold;
display: none;}

.eventsfixed:hover .gotogallery{display: block;}

.eventsfixed:hover .eventimg{transform: scale(1.5);} */

.deptacct{font-weight: bolder;}

#support{padding: 100px;padding-top: 60px;padding-bottom: 60px;font-size: 16px;margin-top: 120px;
background-color:#008a31;color: white;
transition: background-color 4s;
transition-timing-function: ease;
}

#support:hover{background-color: black;color: white;}
/* #support:hover .pay{background-color: white;color: black;font-weight: bold;} */

.inputcont{width: 90%;height: 45px;margin: 0px 0px 0px 0px;margin-left: 5%;}
.input{border: none;height: 100%;width: 48%;border-radius: 4px;padding-left: 5px;background-color: transparent;font-size: 14px;margin-right: 3%;
outline: none;;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);background-color: white;color: black;}
.lebel{color: white;font-size: 14px;margin-bottom: 10px;margin-left: 0px;}
.pay{width:30%;height: 45px;display: inline-block;margin: 0px;font-size: 15px; text-align: center;color: black;padding: 5px;padding-top: 10px;
border-radius: 6px;background-color: yellow;margin-left: 5%;}
.forms{margin-top: -40px;}
select{display: inline-block;width: 48%;height: 100%;border-radius:4px;border: none;background-color: white;}



#leader{padding: 220px;padding-top: 100px;padding-bottom: 0px;text-align: center;}
#lecturers{padding: 160px;padding-top: 50px;text-align: center;padding-bottom:0px;margin-bottom: -50px; display: none;}
/* .leadersCard{width: 100%;height: 250px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);} */
.leadersCard{margin-top: 40px;}
.excoimg{width: 150px;height: 150px;border-radius: 50%;margin-top: 40px;}
.excoimgbig{width: 200px;height: 200px;margin-top: 0px;}
.exconame{margin-top: 10px;font-size: 17px;text-transform: uppercase;font-weight: bold;color: black;}
.exco_position{font-size: 15px;display: block;text-transform: capitalize;font-weight: lighter;}
a{text-decoration: none;}
.allexcos{font-size: 14px;margin-top: 30px;direction: block;color: yelllow;}

.figure{font-size: 36px;font-weight: bold;}
.marks{color: white;font-size: 21px;text-align: center;width: 23%;margin-left: 1%;margin-right: 1%;}
.mark{width: 100%;height: auto;padding: 30px;margin-top: 40px;display: flex;flex-direction:row;background-color: black;margin-bottom: 2px;}
/* rgba(0, 0, 0, 0.865) */
/* background-color: #008a31; */


/* additional style */
.ad_image{width:800px;height: 550px;margin-top: -120px;margin-left: -200px;margin-bottom: -130px;}
/* .big_ad{height: 350px;}  */
/* temporory styles */
.ad2{width: 80%;height: 200px;margin-left: 10%;margin-bottom: 0px;}
.thru{text-align: justify;}
/* temporory styles */


.admissions{padding: 50px;margin-top: 40px;}
.ad_more{width: 180px;padding: 4px;margin-left:0px;margin-right: 0px;display: block;border:none ;padding-top: 8px;margin-top: 20px;
background-color:rgb(255, 255, 0);color:black; text-align: center;font-size: 17px;border-radius:4px;text-decoration: none;height:40px;font-weight: bold;
}
ol{list-style-type:square;}
li{font-size: 17px;margin-bottom: 2px;}
.ad_sub_heads{display: inline-block;width: auto;padding: 4px;margin-left:20px;margin-right: 0px;border:none ;padding-top: 5px;margin-top: 20px;
background-color:transparent;color:black; text-align: center;border: 1px solid green;
font-size: 15px;border-radius:4px;text-decoration: none;height:40px;font-weight: bold;}
.ad_sub_heads1{margin-left: 0px;}
.ad_sub_heads3{width: auto;}
.grahead{text-align: right;margin-right: 20px;}
.lect_img{border-radius:2%;width: 200px;height: 200px;}
.lectimgbig{width: 300px;height: 300px;margin-top: 0px;}

.demostrate{font-size: 17px;text-transform: uppercase;}

.adwriteup{text-align: justify;font-size: 15px;}
.THISWRITE{font-size: 17PX;}
@media screen and (max-width:600px){
#cont{font-size: 15px;}
#about{padding: 20px;padding-top: 40px;font-size: 15px;text-align: justify;}
.universal_btn{width: 150px;padding-top: 5px;margin-top: 20px;font-size: 15px;height: 35px;}
.sectionheader{font-size: 18px;}
#MVG{padding: 20px;padding-top: 80px;padding-bottom: 80px;background-color: white;margin-top: 0px;margin-bottom: 0px;}
.statementconts{padding: 20px;height:auto;width: 100%;font-size: 15px;margin-bottom: 20px;margin-top: 20px;padding-bottom: 20px;}
.goalcont{height: auto;}
#membership{padding: 20px;padding-top: 60px;padding-bottom: 60px;font-size: 15px;margin-top: 0px;margin-bottom: 40px;}
.join_btn{width: 80%;padding: 7px;margin-left:10%;margin-right: 0px;display: block;border:none ;padding-top: 7px;margin-top: 30px;
color:black; text-align: center;font-size: 16px;height:40px;}

/* #events{padding: 20px;padding-top: 0px;padding-bottom: 0px;text-align: center;}
.erow{overflow-x: hidden;}
.eventsfixed{height: 90%;width: 90%;left: 5%;}
.eventimg{width: 90%;height: 90%;transition: transform .2s;}
.eventwriteup{margin-top:20px;text-align: center;} */

#support{padding: 20px;padding-top: 60px;padding-bottom: 60px;font-size: 15px;margin-top: 120px;}
.forms{margin-top: 20px;margin-left: -10px;}

.leadersCard{margin-bottom: 20px;margin-top: 20px;}
#leader{padding: 20px;padding-top: 50px;padding-bottom: 0px;text-align: center;}
#lecturers{padding: 20px;padding-top: 50px;padding-bottom: 0px;margin-bottom: -50px;}

.excoimg{margin-top: 0px;}
.allexcos{font-size: 14px;margin-top: 0px;margin-bottom: 20px;}

.figure{font-size: 20px;font-weight: bold;}
.marks{font-size: 13px;text-align: center;width: 23%;margin-left: 1%;margin-right: 1%;font-weight: bold;}
.mark{width: 100%;height: auto;padding: 5px;margin-top: 40px;display: flex;flex-direction:row;padding-top: 30px;padding-bottom: 30px;}


/* additional style */
.ad_image{width: 110%;height: 300px;margin-left: 0%;margin-top: 0px;margin-bottom: 0px;}

.big_ad{width: 150%;height: 300px;margin-left: -25%;margin-top: 0px;;}






.admissions{padding:20px;margin-top: 40px;}
.ad_more{width: 180px;padding: 4px;margin-left:0px;margin-right: 0px;display: block;border:none ;padding-top: 8px;margin-top: 20px;
background-color:rgb(255, 255, 0);color:black; text-align: center;font-size: 17px;border-radius:4px;text-decoration: none;height:40px;font-weight: bold;
}
ol{list-style-type:square;}
li{font-size: 14px;margin-bottom: 5px;}
.ad_sub_heads{display: inline-block;width: auto;padding: 4px;margin-left:5px;margin-right: 0px;border:none ;padding-top: 5px;margin-top: 20px;
background-color:transparent;color:black; text-align: center;border: 1px solid green;
font-size: 12px;border-radius:4px;text-decoration: none;height:30px;font-weight: bold;margin-bottom: 20px;}
.ad_sub_heads1{margin-left: 0px;}
.ad_sub_heads3{width: auto;margin-top: 0px}
.adwriteup{font-size: 14px;}
.demostrate{font-size: 14px;text-transform: uppercase;}

.lectimgbig{width: 260px;height: 260px;margin-top: 0px;}
li{margin-left: -10px;}
.thru{margin-top: 20px;}
}

</style>