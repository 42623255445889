<template>
<div class="above"><br><br>
<div id="slider" class="">

<div id="slide1" >
<div class="row slide row1 ">
<div class="imgcont">
<img src="../../assets/s3.png" alt="slide1" class="slide_img">
</div>

<div class="writeup">
<div class="writeupinw animate__animated animate__zoomIn" data-aos="zoom-in">
<!-- <div class="comentry">Web development</div> -->
<div class="say">
<div class="sayh">Chemical Engineering Excellence</div>
Welcome to MOUAU Chemical Engineering Department's official website. Discover a world of academic excellence and innovation.
</div><br><br>
<router-link to="/About" class="action_btn">Learn More</router-link>

</div>
</div>
</div>
</div>

<!----------------------------------------------------------------------------- animate__animated animate__fadeInLeft ----->
<div id="slide2" class="none " >
<div class="row slide row2">
<div class="imgcont">
<img src="../../assets/s2.webp" alt="slide1" class="slide_img">
</div>
<div class="writeup">
<div class="writeupinw animate__animated animate__zoomIn" data-aos="slide-left">
<!-- <div class="comentry">Consultancy</div> -->
<div class="say">
  <div class="sayh">Programs and Specializations</div>
    Explore our diverse academic programs: Bachelor's, Master's, and Ph.D.
    Choose from various specializations, including materials, environmental, and process engineering.
    <!-- Gain expertise in industry-relevant skills with a curriculum tailored to real-world challenges.-->
    </div> 
<br><br>

<router-link to="/admission" class="action_btn">Explore</router-link>
</div>

</div>

</div>
</div>



<!----------------------------------------------------------------------------- animate__animated animate__fadeInLeft ----->
<div id="slide3" class="none " >
<div class="row slide row2">
<div class="imgcont">
<img src="../../assets/s1.webp" alt="slide1" class="slide_img">
</div>
<div class="writeup">
<div class="writeupinw animate__animated animate__zoomIn" data-aos="fade-in">
<!-- <div class="comentry">Graphics Designing.</div> -->
<div class="say">
  <div class="sayh">Hands-On Learning Experience</div>
   Immerse yourself in practical learning with state-of-the-art laboratories.
Conduct experiments, design projects, and simulations to enhance your skills.
    </div> 
<br><br>

<router-link to="/about" class="action_btn">Exlore</router-link>
</div>

</div>

</div>
</div>
<div class="pagnitioncont slidepagnitioncont">
<div class="spagnition spag1"></div>
<div class="spagnition spag2"></div>
<div class="spagnition spag3"></div>
</div>
</div>


<!--end of slide-->
</div>
</template>


<script>
import $ from "jquery"
import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
name: 'Slide',
components: {
//Footer,
},
mounted() {AOS.init()
}

}


window.addEventListener('load',start)
var K = 0;


////////////////////////////////////////////////////////////////////////////////////
var animation = () => {
K++
if(K==1){
$("#slide1,#slide3").hide()
$("#slide2").show()
$(".spag2").css("opacity","1").css("background-color","orange")
$(".spag1,.spag3").css("opacity","0.7").css("background-color","white")
}

else if(K==2){
$("#slide3").show()
$("#slide1,#slide2").hide()
$(".spag3").css("opacity","1").css("background-color","orange")
$(".spag1,.spag2").css("opacity","0.7").css("background-color","white")
}

else if(K==3){
$("#slide1").show()
$("#slide2,#slide3").hide()
$(".spag1").css("opacity","1").css("background-color","orange")
$(".spag2,.spag3").css("opacity","0.7").css("background-color","white")
K=0;
}
}


var start = setInterval(animation,16000)




</script>

<style scoped>

#slider{/*background-image: url("../../assets/bg1.jpg");*/background-size: 100%;margin-top: 28px; overflow:hidden;overflow-x: hidden;}
.comentry{font-size: 35px; color: white;margin-bottom: 15px;margin-top: 100px;font-weight:bolder;font-family: "Bell MT", "Courier New CE", "Arial Black", monospace;}
.say{font-size: 22px;color: white;margin-top: 120px;letter-spacing: 2px;border-left: 8px solid rgb(255, 255, 0);padding-left: 10px;}
.sayh{font-size: 25px;text-transform: uppercase;margin-bottom: 10px;}
.action_btn{width: 180px;padding: 7px;height: 40px;margin-left: 0px;margin-right: 20px;display: block;border: none;padding-top: 12px;
background-color: #008a31;color:white; text-align: center;font-size: 17px;border-radius:0px;text-decoration: none;height: 45px;font-weight: bold;
transition: background-color 2s;
transition-timing-function: ease;
}

.action_btn:hover{background-color: black;}

.slide_img{width: 100%;height: 100%;}
.imgcont{padding-right: 0px;text-align: center;width: 100%;height: 558px;}
 .writeup{position: absolute;top: 95px;left: 0px;height: 562px;background-color: #090909c3;z-index: 2;width: 100%;} 
/* #008a30b0 */

.pagnition{height: 6px;width: 20px;border-radius: 26%;margin-left: 15px;margin-right: 15px;background-color: #0070b8;display: inline-block;opacity: 0.2;}
#pag1{opacity: 1;}
.pagnitioncont{margin-top: 20px;text-align: center;}
.writeupinw{width: 60%;float: left;margin-left: 5%;}


.none{display: none;}




@media screen and (max-width:480px){
.resnone{display: none;}
.resview{display: block;color: white;border-left: 5px solid rgb(255, 255, 0);padding-left: 5px}


#slider{padding: 0px 00px 00px 0px;margin-top: 0px;}
.imgcont{width: 100%;height: 320px;}
.writeup{position: absolute;top: 68px;left: 0px;height: 326px;z-index: 2;width: 100%;}
.comentry{font-size: 26px;margin-bottom: 15px;margin-top: 20px;}
.say{font-size: 14px;color: white;margin-top: 50px;}
.sayh{font-size: 15px;color: white;}
.writeupinw{width: 96%;float: left;margin-left: 1%;}
.action_btn{width: 180px;padding: 7px;padding-top: 2px;margin-top: -20px;text-align: center;font-size: 17px;height: 35px;font-weight: bold;}


}
</style>