<template>
<div class="cont">
<Header />
<div class="contact">
<h4 class="sectionheader animate__animated animate__zoomIn">Contact us</h4>

<h5 class="sayh animate__animated animate__zoomIn">We are pleased that you have taken the time to visit our website and we would love to hear from you. Whether you have a question,
     a comment, or a suggestion, we are always here to help.</h5>

<div class="row">
<div class="col-md-1"></div>
<div class="col-md-6">
<div class="contactform">
<form action="">
<div class="inputcont animate__animated animate__zoomIn">
<input type="text" class="inputs" placeholder="Full name">
</div>


<div class="inputcont animate__animated animate__zoomIn">
<input type="email" class="inputs" placeholder="Email Address">
</div>

<textarea name="" id="" placeholder="Message" class="animate__animated animate__zoomIn"></textarea>
<br>
<br>
  
<div class="send animate__animated animate__zoomIn" @:click="alert('Message sent')">Send Message</div>

</form><br>
</div>

</div>
<div class="col-md-4 ot">
    <h5 class="bizh animate__animated animate__fadeInRight">Get in  Touch</h5>
<div class="say animate__animated animate__fadeInRight">You can reach us through various channels, including email, phone, and social media. Our contact details are as follows:</div>

<div class="othercontact">
<img src="../assets/phone.png" alt="" class="contactimg">
 <a class="mcontact link " href="tel:09095255278">(+234) 8109185315 </a><a class="mcontact link" href="tel:09036343095"> -  (+234) 9036343095</a>
 
</div>
<!-- phone,mail,location -->
<div class="othercontact">
<img src="../assets/mail2.png" alt="" class="contactimg">
<a class="mcontact link" href="mailto:naosworldwide328@gmail.com" target="_blank">mouau@chemicalengineering.edu.ng</a>
</div>

<!-- <div class="othercontact">
<img src="../assets/group.png" alt="" class="contactimg">
<a class="mcontact link" href="https://chat.whatsapp.com/EpQb0U9osIjDRNtvpj9Ob6" target="_blank">Join our telegram Community</a>
</div> -->

</div>
<div class="col-md-1"></div>
    
</div>
</div>
<div class="forres desknone">
<!--<Resmenu />-->
</div>

<Resmenu />

<Footer />
</div>
</template>

<script>
import Header from './plugins/header.vue'
import Footer from './plugins/Footer.vue'
import Resmenu from './plugins/rmenu.vue'

export default {
name: 'ContactPage',
components: {
Header,
Footer,
Resmenu
}
}

   

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
.contact{padding: 0px;margin-top: 100px;padding-bottom: 40px;}
.inputcont{width: 90%;margin-left: 0%;height: 40px;margin-bottom: 20px;}
.label{color:#008a31 ;}
.inputs{width: 100%;height: 100%;padding: 4px;border: 2px solid#008a31;border-radius: 5px;
background-color:transparent;color: BLACK;padding: 10px; outline: none;outline: none;}
.contactform{width: 100%;}
textarea{border: 2px solid#008a31; width: 100%;height: 120px;width: 90%;margin-left: 0%;border-radius: 10px;
background-color: transparent;color: black;padding: 10px;outline: none;}
.ml{margin-left: 5%;}
.send{width: 50%;height: 40px;text-align: center;border-radius: 5px;color: white;padding-top: 8px;
background-color:#008a31;margin-left: 20%;}


.othercontact{font-size: 18px;margin-bottom: 20px;margin-top: 30px;}
.contactimg{height: 30px;width: 30px;}
.mcontact{display: inline-block;margin-left: 10px;}
/* .say{color:black;font-weight: bold;;} */

.sectionheader{color:#008a31;text-align:center;text-transform: uppercase;font-weight: bold;margin-bottom: 40px;}

.sayh{margin-bottom: 50px;text-align: center;width: 80%;margin-left: 10%;}
.bizh{color:#008a31;font-weight: bold;}

a,a:visited,.link:hover{text-decoration: none;color: #2c3e50;}

@media screen and (max-width:480px){
.contact{padding: 10px;margin-top: 80px;padding-bottom: 40px;}
.resnone{display: none;}
.contactform{margin-bottom: 40px;margin-top: -20px;}
.ot{padding-left: 8%;}
.mailm{margin-bottom: 20px;}
.othercontact{font-size: 15px;margin-bottom: 20px;}
.contactimg{height: 24px;width: 24px;}
.forres{display: block;}
.sayh{font-size: 15px;}
.inputcont{width: 90%;margin-left: 5%;height: 40px;margin-bottom: 20px;}
.sayh{margin-bottom: 30px;;text-align: center;width: 100%;margin-left: 0%;}
textarea{width: 90%;margin-left: 5%;}
.send{margin-left: 25%;}

.sectionheader{margin-bottom: 20px;font-size: 17px;margin-top: -20px;}

}
</style>
