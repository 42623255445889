var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"above"},[_c('br'),_c('br'),_c('div',{attrs:{"id":"slider"}},[_c('div',{attrs:{"id":"slide1"}},[_c('div',{staticClass:"row slide row1"},[_vm._m(0),_c('div',{staticClass:"writeup"},[_c('div',{staticClass:"writeupinw animate__animated animate__zoomIn",attrs:{"data-aos":"zoom-in"}},[_vm._m(1),_c('br'),_c('br'),_c('router-link',{staticClass:"action_btn",attrs:{"to":"/About"}},[_vm._v("Learn More")])],1)])])]),_c('div',{staticClass:"none",attrs:{"id":"slide2"}},[_c('div',{staticClass:"row slide row2"},[_vm._m(2),_c('div',{staticClass:"writeup"},[_c('div',{staticClass:"writeupinw animate__animated animate__zoomIn",attrs:{"data-aos":"slide-left"}},[_vm._m(3),_c('br'),_c('br'),_c('router-link',{staticClass:"action_btn",attrs:{"to":"/admission"}},[_vm._v("Explore")])],1)])])]),_c('div',{staticClass:"none",attrs:{"id":"slide3"}},[_c('div',{staticClass:"row slide row2"},[_vm._m(4),_c('div',{staticClass:"writeup"},[_c('div',{staticClass:"writeupinw animate__animated animate__zoomIn",attrs:{"data-aos":"fade-in"}},[_vm._m(5),_c('br'),_c('br'),_c('router-link',{staticClass:"action_btn",attrs:{"to":"/about"}},[_vm._v("Exlore")])],1)])])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgcont"},[_c('img',{staticClass:"slide_img",attrs:{"src":require("../../assets/s3.png"),"alt":"slide1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"say"},[_c('div',{staticClass:"sayh"},[_vm._v("Chemical Engineering Excellence")]),_vm._v(" Welcome to MOUAU Chemical Engineering Department's official website. Discover a world of academic excellence and innovation. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgcont"},[_c('img',{staticClass:"slide_img",attrs:{"src":require("../../assets/s2.webp"),"alt":"slide1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"say"},[_c('div',{staticClass:"sayh"},[_vm._v("Programs and Specializations")]),_vm._v(" Explore our diverse academic programs: Bachelor's, Master's, and Ph.D. Choose from various specializations, including materials, environmental, and process engineering. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgcont"},[_c('img',{staticClass:"slide_img",attrs:{"src":require("../../assets/s1.webp"),"alt":"slide1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"say"},[_c('div',{staticClass:"sayh"},[_vm._v("Hands-On Learning Experience")]),_vm._v(" Immerse yourself in practical learning with state-of-the-art laboratories. Conduct experiments, design projects, and simulations to enhance your skills. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagnitioncont slidepagnitioncont"},[_c('div',{staticClass:"spagnition spag1"}),_c('div',{staticClass:"spagnition spag2"}),_c('div',{staticClass:"spagnition spag3"})])
}]

export { render, staticRenderFns }