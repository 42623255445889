<template >
<div class="each_excos_details resnone">
<img src="../../assets/x.png" alt="" class="back desknone" v-on:click="hidemenu()">

<div class="exco exco1">
<div class="text-center">
<div class="userimgcont"><img src="../../assets/excos/president.jpg" alt="" class="excodetailsimg"></div>
<div class="exconame">Emeruwa C. Wisdom<span class="exco_position"> President  General</span> </div>
</div>
<div class="aboutexco">
Comr. Emeruwa C. Wisdoma is a 500 level student of chemical Engineering MOUAU, He is a dynamic and visionary leader with a passion for empowering young people. 
As the President of the Nigeria Society of Chemical Engineering Students,he is responsible for providing strategic direction for the organization, overseeing its activities and ensuring that the interests of chemical engineering students are well represented.
</div>
</div>


<!-- <div class="exco exco2 none">
<div class="text-center">
<div class="userimgcont"><img src="../../assets/excos/VP.jpg" alt="" class="excodetailsimg"></div>
<div class="exconame">Ukoha Precious Kalu<span class="exco_position"> Vice President</span> </div>
</div>
<div class="aboutexco">
Miss. Ukoha Preious hails from Elu chemical engineering, a graduate of Abia Polytechnic, she is a committed and dedicated student leader with a passion for promoting the welfare of her fellow students. As Vice President of the nigeria Association of chemical engineering Students,
 she works closely with the President and other members of the Executive Committee to advance the goals of the organization and to ensure that the needs of chemical engineering students are met.
</div>
</div> -->


<div class="exco exco3 none">
<div class="text-center">
<div class="userimgcont"><img src="../../assets/excos/secretary.jpeg" alt="" class="excodetailsimg"></div>
<div class="exconame">Ukonu George Chukwuebuka<span class="exco_position"> General Secretary</span> </div>
</div>
<div class="aboutexco">
Mr. Ukonu George is a 500 level student of chemical Engineering MOUAU, he is a skilled administrator with a wealth of experience in managing complex organizations. 
As Secretary of the Nigeria Society of Chemical Engineering Students, he is responsible for overseeing the day-to-day operations of the organization and ensuring that its administrative functions run smoothly.
</div>
</div>

<!-- <div class="exco exco4 none">
<div class="text-center">
<div class="userimgcont"><img src="../../assets/excos/ASG.png" alt="" class="excodetailsimg"></div>
<div class="exconame">Thompson Ogechi<span class="exco_position"> Assistant General Secretary</span> </div>
</div>
<div class="aboutexco">
Mr. Ukonu George hails from Elu chemical engineering, a student of Gregory University Uturu,
As the assistant secretary of the nigeria Association of chemical engineering, she works closely with the secretary to oversee the day-to-day operations of the organization and
 ensure that its administrative functions run smoothly.
</div>
</div> -->






</div>





</template>


<script>



import AOS from 'aos'
import 'aos/dist/aos.css'
import $ from "jquery"


export default {
name: 'Excos_about',
components: {

}, 
data() { 
return {
//balance:0,
}},
methods:{ 
hidemenu: function(){
$(".each_excos_details").hide(100)
}
},
//computed(){},
mounted(){
AOS.init()

$(".excohead1").click(function(){
$(".exco1,.each_excos_details").show()
$(".exco2,.exco3,.exco4").hide()
$(".excohead1").css("border","6px solid #008a31")
$(".excohead2,.excohead3,.excohead4").css("border","none")

})

// $(".excohead2").click(function(){
// $(".exco2,.each_excos_details").show()
// $(".exco1,.exco3,.exco4").hide()
// $(".excohead2").css("border","4px solid #008a31")
// $(".excohead1,.excohead3,.excohead4").css("border","none")

// })

$(".excohead3").click(function(){
$(".exco3,.each_excos_details").show()
$(".exco2,.exco1,.exco4").hide()
$(".excohead3").css("border","6px solid #008a31")
$(".excohead2,.excohead1,.excohead4").css("border","none")

})

// $(".excohead4").click(function(){
// $(".exco4,.each_excos_details").show()
// $(".exco2,.exco3,.exco1").hide()
// $(".excohead4").css("border","4px solid #008a31")
// $(".excohead2,.excohead3,.excohead1").css("border","none")

// })


}


}

</script>

<style scoped>
.none{display: none;}

/* /////////////////// */
.each_excos_details{width:90%;background-color: white; border-top-left-radius: 12px;border-Bottom-right-radius: 5%;height:auto;margin-left: 4%;
padding-bottom: 20px;margin-top: 40px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);text-align: justify;}
.userimgcont{ width: 120px;background-color: rgba(223, 223, 223, 0.541);height: 120px;border-radius: 50%;display: inline-block;margin-top: 40px;margin-bottom: 10px;}
.excodetailsimg{width: 100%;height: 100%;border-radius: 50%;}
.aboutexco{font-size: 16px;padding: 20px;text-align: left;}
.exco_position{font-size: 15px;display: block;text-transform: capitalize;font-weight: lighter;}

.desknone{display: none;}

.exco{text-align: justify;}

@media screen and (max-width:480px){
.resnone{display: none;}
.each_excos_details{width:90%;margin-left: 0%;position: absolute;top: 20%;}
.aboutexco{font-size: 14px;padding: 10px;}
.back{width:20px;height:20px;margin-top: 10px;float: right;margin-right: 10px;display: block;}

}

</style>