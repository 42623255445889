<template>

<div class="footer">

<div class="row">
<div class="col-md-5" data-aos="slide-left">
     <span class="site_name">Chemical Engineering MOUAU</span>
    <div class="fwrite">
The Chemical Engineering Department at MOUAU is a dynamic and innovative academic unit committed to excellence in teaching, research, and community engagement.
 Established with a vision to nurture proficient chemical engineers, 
we strive to produce graduates who can effectively address the global challenges of the chemical industry and sustainable development.</div>
<div class="socialicons">
<!-- <a class="mcontact link" href="https://www.facebook.com/profile.php?id=100085007283184" target="_blank"><img src="../../assets/facebookw.png" alt="Facebook" class="social_icon"></a>
<a class="mcontact link" href="https://chat.whatsapp.com/EpQb0U9osIjDRNtvpj9Ob6" target="_blank"><img src="../../assets/twitterw.png" alt="Whatsapp" class="social_icon"></a>
<a class="mcontact link" href="https://t.me/NAOS_WORLDWIDE" target="_blank"><img src="../../assets/telegramw.png" alt="Telegram" class="social_icon"></a>
<a class="mcontact link" href="mailto:naosworldwide328@gmail.com" target="_blank"><img src="../../assets/mailw.png" alt="Gmail" class="social_icon gmail_icon"></a> -->


<a class="mcontact link" href="" target="_blank"><img src="../../assets/facebookg.png" alt="Facebook" class="social_icon"></a>
<a class="mcontact link" href="" target="_blank"><img src="../../assets/twitterg.png" alt="Whatsapp" class="social_icon"></a>
<a class="mcontact link" href="" target="_blank"><img src="../../assets/telegramg.png" alt="Telegram" class="social_icon"></a>
<a class="mcontact link" href="" target="_blank"><img src="../../assets/mailg.png" alt="Gmail" class="social_icon gmail_icon"></a>

</div>
</div>
<div class="col-md-3 c">
<div class="navhead">Site Links</div>

<div class="fnavso cnavf">
<router-link to="/About" class="whatweofferfoot navf">About Us</router-link>  
<router-link to="/Contact" class="whatweofferfoot navf">Contact Us</router-link> </div>

<div class="fnavso cnavf">
<router-link to="/Executives" class="whatweofferfoot navf">Executives</router-link>
<router-link to="/Presidents" class="whatweofferfoot navf resnone">Academic Staffs</router-link>
</div>
<div class="fnavso cnavf">
<router-link to="/admission" class="whatweofferfoot navf">Guidelines</router-link>
<router-link to="/FAQ" class="whatweofferfoot navf">FAQ</router-link>

<!-- <router-link to="Gallery" class="whatweofferfoot navf resnone">Gallery</router-link> -->
<!-- <router-link to="/" class="whatweofferfoot navf">Resources</router-link> -->
</div>

<div class="fnavso cnavf">
<span class="whatweofferfoot navf resnone">Resources</span>
<router-link to="/about_association" class="whatweofferfoot navf">About Association</router-link>

</div>


</div>

<div class="col-md-4 c">
<div class="navhead">Online tools</div>
<a class="fnavso navf" href="https://timblee.netlify.app" target="_blank">Result Calculator</a>
<a class="fnavso navf" href="https://nsche-bot.netlify.app" target="_blank">NSCHE Bot</a>
<!-- <router-link to="/Map" class="fnavso navf">Downlaod NAOS constitution</router-link> -->


<div class="newslatter inputcont">
<span class="lebel">Signup for New Letter</span>
<input type="text" placeholder="Enter your Email" class="input">
<div class="newsletter_btn">Subscribe</div>

</div>

</div>
</div>

<br>
<div class="copy">
All Rights Reserved  <b>©2023</b> Chemical Engineering Department MOUAU | Powered by <a class="eche" style="font-weight:bold" href="https://www.echelontech.com.ng" target="_blank">Echelon Tech LLC</a> 
</div>


<!-- absolute position -->
<a class="navs" href="https://nsche-bot.netlify.app" target="_blank">
<div class="fixedboticon animate__animated animate__bounceIn animate__delay-2s animate__repeat-1">
  <img src="../../assets/bot.png" alt="" class="bot_icon">
</div></a>
</div>
</template>


<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
name: 'Footer',
components: {
//Footer,
},
mounted() {AOS.init()}


}
</script>

<style>
/* #d63c55 */
.footer{background-color: black;padding: 10px;padding-bottom: 0px;color: white;margin-bottom: -10px;margin-top: 0px;}
/* #008a31 */
.navhead{font-size: 20px;margin-bottom:10px;color: white;font-weight: bolder;}

.fnav{margin-left: 40px;}
.fn2{margin-left: 100px;}
.copy{font-size: 12px; color: white;text-align:center;width: 100%;text-transform: uppercase;}

.none{display: none;}
.router-link,a{color: white;text-decoration: none;}


.whatweofferfoot{font-size: 14px;display: inline-block;margin-right: 28px;}
.fnavso{display: block;font-size: 14px;margin-bottom: 20px;text-decoration: none;color: white;}
.fwrite{text-align: justify;width: 90%;margin-left: 0%;margin-top: 0px;font-size: 14px;}
.site_name{font-size: 19px;font-weight: bold;margin-bottom: 10px;display: block;color: white;text-transform: uppercase;}


.logoimg{width: 50px;height: 50px;border-radius: 50%;margin-top: -12px;display: block;margin-left: 250px;}
.socialicons{display: flex;margin-top: 30px;}
.social_icon{height: 30px;width:30px;padding: 0px;margin-right: 30px;border-radius:0%;}
.gmail_icon{border-radius:5%;}

.inputcont{width: 100%;height: 40px;margin: 20px 0px 40px 0px;display: row;}
input{border: none;height: 100%;width: 74%;border-radius: 0px;border: 2px solid white;padding-left: 5px;background-color: transparent;font-size: 14px;color: white;
outline: none;display: inline-block;}
.lebel{color: white;font-size: 18px;margin-bottom: 10px;margin-left: 0px;font-weight: bold;margin-top: 10px;display: block;}

.newsletter_btn{width: 22%;padding: 7px;border:none ;padding-top: 8px;float: right;
background-color: yellow;color:black; text-align: center;font-size: 14px;border-radius:0px;text-decoration: none;height:100%;font-weight: bold;}


.navf{color: white;}

a:hover{color: white;}

.bot_icon{width: 50px;height: 50px; padding: 5px;}
.fixedboticon{padding: 10px;background-color: #008a31;position: fixed;top: 80%;right: 5%;border-radius: 25%;transition: background-color 4s;
transition-timing-function: ease;z-index: 999;}

.fixedboticon:hover{background-color: black;}
.router-link-active {color: #008a31;font-weight: bold;}
.allexcos,.allexcos:hover{color: #008a31;}

@media screen and (max-width:480px){
/* .flogoo{width: 600px;height: 80px;margin-top: -30px;margin-left: -70px;margin-bottom: -20px;} */

.navhead{font-size: 16px;margin-bottom:10px;}
.c{display: inline-block;width: 48%;margin-top: 40px;}
.fnav{margin-left: 0px;}
.fn2{margin-left: 0px;}
#footer{background-color: #2d2c36;padding: 0px;padding-bottom: 0px}
.frow1{padding: 0px;margin-bottom: 10px;}
.copy{padding-left: 0px;text-align: center;padding: 0px;font-size: 10px;width: 100%;}

.whatweofferfoot{font-size: 14px;display: inline-block;margin-right: 12px;}
.cnavf{margin-bottom: 0px;}
.navf{display: block;margin-bottom: 10px;font-size: 14px;}
.fwrite{text-align: left;width: 100%;margin-left: 0%;font-size: 14px;display: block;margin-top:10px ;}
.fnavso{color: white;}

.newslatter{display: none;}
.social_icon{height: 30px;width:30px;padding: 5px;margin-right: 30px;}
.resnone{display: none;}
.site_name{font-size: 19px;font-weight: bold;}
.bot_icon{width: 30px;height: 30px; padding: 5px;}

} 
</style>
