<template >
<div class="cont"> 
<Header/>


<div id="tips">


<div class="row padding">

<div class="col-md-3">
<div class="rightmenu">

<div class="tiphead">first year tips</div>
<hr>
<router-link to="/admission" class="tipnav_cont">
<div class="tipnav">Admission Requirments</div>
<div class="tipsubcontent">
<div class="tipcontents utme">UTME entry requirements</div>
<div class="tipcontents direct">Direct entry requirements</div>
</div>
</router-link>

<router-link to="/Registration" class="tipnav_cont">
<div class="tipnav">Registration and matriculation</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Registration period</div>
<div class="tipcontents direct">Registration procedure</div>
</div>
</router-link>


<router-link to="/grading_system" class="tipnav_cont">
<div class="tipnav">Grading System</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Grading system breakdown</div>
<div class="tipcontents direct">Score ranking</div>
<div class="tipcontents period">Period of formal study</div>
</div>
</router-link>

<router-link to="/Examination_tips" class="tipnav_cont">
<div class="tipnav">Examination offenses and discipline</div>
</router-link>

<router-link to="/career_opportunities" class="tipnav_cont">
<div class="tipnav">Career opportunities for chemical engineer</div>
</router-link>

<div class="tipnav_cont">
<div class="tipnav">Result calculation tips</div>
</div>

<!-- end -->
</div>
</div>

<div class="col-md-9">

<div class="mainhead">DEPARTMENTAL ADMISSION REQUIREMENTS</div>
<hr>


<!-- cont1 -->
<div class="tipcont1"> 
 <div class="contents">
<span class="content_title UTME_contnet">UTME entry requirements</span>
The Department offers a 5-year programme for the Bachelor of Engineering (B.Eng) Honours Degree in Chemical Engineering. 
The admission requirements for UTME candidates into the course is a minimum of 5 (ordinary Level) credit pass in West Africa  Senior Secondary School 
Certificate Examination  (WASSCE),  General  Certificate   of  Education (GCE) or Nigerian Examination Council Ordinary Level (NECO) in not more than two 
 sittings.  The credits  must  include  English Language, Mathematics, Physics, and Chemistry. The fifth credit should be any of Biology, Further Mathematics or
  Agricultural Science. The candidate must not be less than 16 years of age. He or she must have a
 UTME score that meets the Departmental cut-off mark determined by the University and Post-UTME screening test.
 </div>
<div class="contents">
<span class="content_title direct_contnet">Direct entry requirements</span>
Direct entry admission is based on a combination of Ordinary Level results
With the following qualifications:
<ul>
<li class="tt">G.C.E or HSC ('A' Level) with two papers in Physics, Chemistry or
Mathematics.</li>
<li class="tt">	OND (Upper credit)</li>
<li class="tt">HND (Upper credit)</li>
</ul>
Candidates with qualifications as in (i) and (ii) above may be admitted into 200 level and those with qualifications in (iii) may be admitted into 3001evel. All applicants must meet a minimum requirement of 5 'O' Level credit passes in relevant subjects as specified in (1) above.
</div>
</div>
<!-- cont1 end -->


</div>


</div>
</div>
<br>
<br>
<br>

<Resmenu />

<div class="footer row">
<Footer />
</div>

</div>




</template>


<script>

import Footer from '../plugins/Footer.vue'
import Header from '../plugins/header.vue'
import Resmenu from '../plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
name: 'Addmission',
components: {
Header,
Footer,
Resmenu

}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

}


}

</script>

<style scoped>

.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
.sectionheader{color:#008a31;font-size: 22px;text-transform: uppercase;font-weight: bold;}
#tips{padding: 20px;padding-bottom: 0px;margin-top: 60px;}

.tiphead{font-size: 20px;text-transform: capitalize;}
.mainhead{font-size: 22px;}
.tipnav{font-size: 17px;;cursor: pointer;}
.tipsubcontent{font-size: 16px;border-left: 1px solid rgba(0, 0, 0, 0.534);margin-left: 10px;padding-left: 5px;}
.tipcontents{margin-top: 0px;margin-bottom: 0px;cursor: pointer;padding: 10px;padding-top: 5px;}
/* .tipnav_cont:hover{background-color: rgba(247, 247, 247, 0.898);width: auto;} */
.tipnav_cont{margin-bottom: 20px;}
.content_title{text-transform: capitalize;font-weight: bold;display: block;margin-bottom: 5px;}
.tt{list-style-type: lower-roman;}
.contents{margin-top: 30px;text-align: justify;}

.router-link,a{color: black;}
.router-link-active{background-color: rgba(247, 247, 247, 0.898);width: auto;display: block;padding: 5px;}
.router-link-active .tipnav{font-weight: bold;}

@media screen and (max-width:600px){
.resnone{display: none;}
.mainhead{font-size: 16px;margin-top: 20px;}
#cont{font-size: 15px;}
#tips{padding: 10px;padding-bottom: 0px;margin-top: 35px;}
.tiphead{font-size: 17px;text-transform: capitalize;}
.padding{padding: 10px;}

}

</style>