<template >
<div class="cont"> 
<Header/>


<div id="tips">


<div class="row padding">

<div class="col-md-3">
<div class="rightmenu">

<div class="tiphead">first year tips</div>
<hr>
<router-link to="/admission" class="tipnav_cont">
<div class="tipnav">Admission Requirments</div>
<div class="tipsubcontent">
<div class="tipcontents utme">UTME entry requirements</div>
<div class="tipcontents direct">Direct entry requirements</div>
</div>
</router-link>

<router-link to="/Registration" class="tipnav_cont">
<div class="tipnav">Registration and matriculation</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Registration period</div>
<div class="tipcontents direct">Registration procedure</div>
</div>
</router-link>


<router-link to="/grading_system" class="tipnav_cont">
<div class="tipnav">Grading System</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Grading system breakdown</div>
<div class="tipcontents direct">Score ranking</div>
<div class="tipcontents period">Period of formal study</div>
</div>
</router-link>

<router-link to="/Examination_tips" class="tipnav_cont">
<div class="tipnav">Examination offenses and discipline</div>
</router-link>

<router-link to="/career_opportunities" class="tipnav_cont">
<div class="tipnav">Career opportunities for chemical engineer</div>
</router-link>

<div class="tipnav_cont">
<div class="tipnav">Result calculation tips</div>
</div>


<!-- end -->
</div>
</div>

<div class="col-md-9">

<div class="mainhead">REGISTRATION AND MATRICULATION</div>
<hr>


<!-- cont1 -->
<div class="tipcont1"> 
    Each student shall be required to present himself/herself for registration for the programme of study for which he/she has
     been accepted in accordance with the procedure approved from time to time by the university
 <div class="contents">
<span class="content_title UTME_contnet">Registration period</span>
<ul class="tt">
<li>	Registration for various courses under the programme shall take place once per session at the beginning of the semester. Registration closes 5 days from the date of commencement of
     registration. All completed registration forms must be submitted to the office of the 1 load of Department within the stipulated registration window. Submission of registration forms beyond the
      registration  deadline will attract late registration fee. </li>
<li>	Late registration may be allowed in exceptional cases with the special permission of the Registrar in consultation with the school upon the payment of a fee. 
    No late registration will be allowed more than two weeks after lectures begin.</li>
<li>	If for some genuine reasons a student is unable to return to the campus to register within the two weeks period stipulated, the Registrar must be notified promptly in writing.
     Approval for such late registration shall be given by the senate. In exceptional cases, the Vice-Chancellor may act on behalf of the senate.</li>
<li>	All completed forms must reach the Dean's office not later than two weeks after commencement of lectures.</li>
<li>	Students shall not be allowed to attend classes or laboratory or to use the University Library or any other facility of the University until they are registered.</li>
</ul>
</div>
<div class="contents">
<span class="content_title direct_contnet">Registration procedure</span>

<ul>
<li class="tt">Freshmen shall on arrival report at the Registry for identification and then at the Health Center for health clearance.</li>
<li class="tt">Students pay the prescribed fees at the student's accounts of the Student Affairs Unit.</li>
<li class="tt">Students  shall present official receipt  at  the  Academic Affairs Division of the Registry to show proof of payment.</li>
<li class="tt">Students obtain academic clearance and thereafter collect five course registration forms from the Registry, (or from any other place designated for that purpose). 
    The forms are stamped "VALID" before collection.</li>
<li class="tt">Students proceeds to their respective colleges.</li>
<li class="tt">If any changes, the student should inform the Registrar.</li>
<li class="tt">Copies of the signed forms are distributed by the Dean to the Head of Departments, Registrar, Director  of Academic Planning.</li>
</ul>
</div>
</div>
<!-- cont1 end -->


</div>


</div>
</div>
<br>
<br>
<br>

<Resmenu />

<div class="footer row">
<Footer />
</div>

</div>




</template>


<script>

import Footer from '../plugins/Footer.vue'
import Header from '../plugins/header.vue'
import Resmenu from '../plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
name: 'Addmission',
components: {
Header,
Footer,
Resmenu

}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

}


}

</script>

<style scoped>

.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
.sectionheader{color:#008a31;font-size: 22px;text-transform: uppercase;font-weight: bold;}
#tips{padding: 20px;padding-bottom: 0px;margin-top: 60px;}

.tiphead{font-size: 20px;text-transform: capitalize;}
.mainhead{font-size: 22px;}
.tipnav{font-size: 17px;cursor: pointer;}
.tipsubcontent{font-size: 16px;border-left: 1px solid rgba(0, 0, 0, 0.534);margin-left: 10px;padding-left: 5px;}
.tipcontents{margin-top: 0px;margin-bottom: 0px;cursor: pointer;padding: 10px;padding-top: 5px;}
/* .tipnav_cont:hover{background-color: rgba(247, 247, 247, 0.898);width: auto;} */
.tipnav_cont{margin-bottom: 20px;}
.content_title{text-transform: capitalize;font-weight: bold;display: block;margin-bottom: 5px;}
.tt{list-style-type: lower-roman;}
.contents{margin-top: 30px;text-align: justify;}

.router-link,a{color: black;}
.router-link-active{background-color: rgba(247, 247, 247, 0.898);width: auto;display: block;padding: 5px;}
.router-link-active .tipnav{font-weight: bold;}

@media screen and (max-width:600px){
.resnone{display: none;}
.mainhead{font-size: 16px;margin-top: 20px;}

#cont{font-size: 15px;}
#tips{padding: 10px;padding-bottom: 0px;margin-top: 35px;}
.tiphead{font-size: 17px;text-transform: capitalize;}
.padding{padding: 10px;}

}

</style>