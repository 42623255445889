<template >
<div class="cont"> 
<Header/>


<div id="tips">


<div class="row padding">

<div class="col-md-3">
<div class="rightmenu">

<div class="tiphead">first year tips</div>
<hr>
<router-link to="/admission" class="tipnav_cont">
<div class="tipnav">Admission Requirments</div>
<div class="tipsubcontent">
<div class="tipcontents utme">UTME entry requirements</div>
<div class="tipcontents direct">Direct entry requirements</div>
</div>
</router-link>

<router-link to="/Registration" class="tipnav_cont">
<div class="tipnav">Registration and matriculation</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Registration period</div>
<div class="tipcontents direct">Registration procedure</div>
</div>
</router-link>


<router-link to="/grading_system" class="tipnav_cont">
<div class="tipnav">Grading System</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Grading system breakdown</div>
<div class="tipcontents direct">Score ranking</div>
<div class="tipcontents period">Period of formal study</div>
</div>
</router-link>

<router-link to="/Examination_tips" class="tipnav_cont">
<div class="tipnav">Examination offenses and discipline</div>
</router-link>

<router-link to="/career_opportunities" class="tipnav_cont">
<div class="tipnav">Career opportunities for chemical engineer</div>
</router-link>

<div class="tipnav_cont">
<div class="tipnav">Result calculation tips</div>
</div>


<!-- end -->
</div>
</div>

<div class="col-md-9">

<div class="mainhead">EXAMINATION OFFENCES AND DISCIPLINE</div>
<hr>


<!-- cont1 -->
<div class="tipcont1"> 
 <div class="contents">
<span class="content_title UTME_contnet">Examination offences will include:</span>
<ul class="tt">
<li>Disobedience to the invigilators during an examination. </li>
<li>Failure to adhere to time requirements during examination.</li>
<li>Involving in any act capable of disturbing the smooth conduct of an examination.</li>
<li>Involvement in any examination malpractice which includes acts such as: 
<ul class="tA">
<li>Communication with any student or students in any manner daring an examination.</li>
<li>Receiving  assistance  from or  giving  assistance   to  another student or students during the examination.</li>
<li>Impersonation in any examination.</li>
<li>Copying or reading from another student’s script or from any other unauthorized material during the examination.</li>
<li>Opening of one's script or material  for another student or students to copy or read from during the examination.</li>
<li>Being   in possession in an  examination   lull/room  of  any unauthorized material such as books, notes, papers, devices and manuscripts,
     whether or not such  material  is related to the examination.</li>
<li>Involvement in leaking of examination question or paper.</li>
<li>Forging or otherwise altering and presenting medical report in order to obtain a deferment of examination or any other benefit.</li>
<li>Lobbying for examination grades by whatever means.</li>
<li>Involving in any other form of cheating or other acts indented to confer undue advantage on the student.</li>
<li>Any   student   who   aids,   abets   or   covers   an   examination malpractice shall be guilty as a principal offender and liable to punishment. </li>
</ul>
</li>
</ul>


Students  involved in examination offences such as i, ii, and iii above will be disciplined by any of the following means:
<ul class="">
<li> Rustication</li>
<li>Ejection from the University hall of residence.</li>
<li>Other measures deemed fit by the university Authority</li>
</ul>




</div>

</div>
<!-- cont1 end -->


</div>


</div>
</div>
<br>
<br>
<br>

<Resmenu />

<div class="footer row">
<Footer />
</div>

</div>




</template>


<script>

import Footer from '../plugins/Footer.vue'
import Header from '../plugins/header.vue'
import Resmenu from '../plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
name: 'Addmission',
components: {
Header,
Footer,
Resmenu

}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

}


}

</script>

<style scoped>

.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
.sectionheader{color:#008a31;font-size: 22px;text-transform: uppercase;font-weight: bold;}
#tips{padding: 20px;padding-bottom: 0px;margin-top: 60px;}

.tiphead{font-size: 20px;text-transform: capitalize;}
.mainhead{font-size: 22px;}
.tipnav{font-size: 17px;;cursor: pointer;}
.tipsubcontent{font-size: 16px;border-left: 1px solid rgba(0, 0, 0, 0.534);margin-left: 10px;padding-left: 5px;}
.tipcontents{margin-top: 0px;margin-bottom: 0px;cursor: pointer;padding: 10px;padding-top: 5px;}
/* .tipnav_cont:hover{background-color: rgba(247, 247, 247, 0.898);width: auto;} */
.tipnav_cont{margin-bottom: 20px;}
.content_title{text-transform: capitalize;font-weight: bold;display: block;margin-bottom: 5px;}
.tt{list-style-type: lower-roman;}
.contents{margin-top: 30px;text-align: justify;}

.router-link,a{color: black;}
.router-link-active{background-color: rgba(247, 247, 247, 0.898);width: auto;display: block;padding: 5px;}
.router-link-active .tipnav{font-weight: bold;}
.tA{list-style-type: lower-alpha;}


@media screen and (max-width:600px){
.resnone{display: none;}
.mainhead{font-size: 16px;margin-top: 20px;}
#cont{font-size: 15px;}
#tips{padding: 10px;padding-bottom: 0px;margin-top: 35px;}
.tiphead{font-size: 17px;text-transform: capitalize;}
.padding{padding: 10px;}

}

</style>