<template >
<div class="cont"> 
<Header/>


<div id="leader">
<div class="sectionheader" data-aos="fade-in">NSChE leadership</div>

<div class="write" data-aos="fade-in">
Welcome to the Executive Page of the Nigeria Socieety of Chemical Engineering Students (NSChE). We are a community-driven organization dedicated to promoting the interests and welfare of chemical engineering people both at home and abroad.

Our organization is led by a team of dedicated and visionary executives who are committed to ensuring that the nigeria Association of chemical engineering continues to be a positive force for change in our community.
<br>
<b>Meet our Executive Team:</b>

</div>

<div class="row">
<div class="col-md-8">

<!-- excos row 1 -->
<div class="row">
<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/VP.png" alt="" class="excoimg excohead2">
<div class="exconame">Johnson Chidinma F.<span class="exco_position"> Vice President </span> </div>
</div></div>

<div class="col-md-4 c">
<div class="leadersCard" data-aos="zoom-in"> 
<img src="../assets/excos/president.jpg" alt="" class="excoimg excoimgbig excohead1">
<div class="exconame">Emeruwa C. Wisdom<span class="exco_position"> President</span> </div>
</div></div>
<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/secretary.jpeg" alt="" class="excoimg excohead3">
<div class="exconame">Ukonu George C. <span class="exco_position"> Secretary General </span> </div>
</div></div>
</div>


<!-- excos row 2 -->
<div class="row">
<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/ASG.jpg" alt="" class="excoimg excoimg2 excohead4">
<div class="exconame">Nwachukwu Queen <span class="exco_position"> Asst Secretary General </span> </div>
</div></div>

<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/finance.jpg" alt="" class="excoimg excoimg2">
<div class="exconame">Igwe Emmanuel O. <span class="exco_position"> Director of Finance </span> </div>
</div></div>

<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/project.png" alt="" class="excoimg excoimg2">
<div class="exconame">Tamuno Godknows V. <span class="exco_position"> Director of Project</span> </div>
</div></div>
</div>



<!-- excos row 3 -->
<div class="row">
<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/social.jpg" alt="" class="excoimg excoimg2">
<div class="exconame">Emmanuel Chukwuemeka J.  <span class="exco_position"> Director of Socials </span> </div>
</div></div>

<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/treasurer.jpg" alt="" class="excoimg excoimg2">
<div class="exconame">Peters Favour C<span class="exco_position"> Treasurer </span> </div>
</div></div>

<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/sport.jpg" alt="" class="excoimg excoimg2">
<div class="exconame">Chilaka Temple C. <span class="exco_position"> Director of Sport </span> </div>
</div></div>
</div>



<!-- excos row 3 -->
<div class="row">

<div class="col-md-4  c">
<div class="leadersCard" data-aos="zoom-in">
<img src="../assets/excos/pro.jpg" alt="" class="excoimg excoimg2">
<div class="exconame">Nwaoha Daniel E.  <span class="exco_position"> Public Relations Officer </span> </div>
</div></div>

</div>

</div>

<div class="col-md-4">

<Excosabout />

</div>
</div>
</div>
<br>

<Resmenu />

<div class="footer row">
<Footer />
</div>

</div>




</template>


<script>

import Footer from './plugins/Footer.vue'
import Header from './plugins/header.vue'
import Excosabout from './plugins/Excos_about.vue'
import Resmenu from './plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
name: 'Dashboard',
components: {
Header,
Footer,
Excosabout,
Resmenu

}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

}


}

</script>

<style scoped>

.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
.sectionheader{color:#008a31;font-size: 22px;text-transform: uppercase;font-weight: bold;}


#leader{padding: 20px;padding-bottom: 0px;text-align: center;margin-top: 60px;}
/* .leadersCard{width: 100%;height: 250px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);} */
.leadersCard{margin-top: 40px;}
.write{padding: 60px;font-size: 17px;padding-top: 10px;padding-bottom: 20px;}
.excoimg{width: 150px;height: 150px;border-radius: 50%;margin-top: 0px; ;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);}
.excoimg2{margin-top: 0px;width: 120px;height: 120px;}
.excoimgbig{width: 200px;height: 200px;margin-top: 0px;border: 6px solid#008a31;}

/* .excoimg:hover{border: 6px solid#008a31;} */

.exconame{margin-top: 10px;font-size: 17px;text-transform: uppercase;font-weight: bold;}
.exco_position{font-size: 15px;display: block;text-transform: capitalize;font-weight: lighter;}
a{text-decoration: none;}
.allexcos{font-size: 14px;margin-top: 30px;direction: block;color: #f80800;}




@media screen and (max-width:600px){
.resnone{display: none;}

.write{padding: 0px;font-size: 15px;padding-top: 10px;padding-bottom: 0px;}
.excoimgbig{border:none;}
.sectionheader{margin-bottom: 20px;font-size: 17px;margin-top: -20px;}


/* .leadersCard{width: 100%;height: 100%;} */

.excoimg{width: 70px;height: 70px;}
.excoimg2{margin-top: 0px;width: 50px;height: 50px;}
.excoimgbig{width: 90px;height: 90px;margin-right: 3px;}
.c{display: inline-block;width:33%;margin-top: 0px;}
.exconame{margin-top: 10px;font-size: 12px;text-transform: uppercase;font-weight: bold;}
.exco_position{font-size: 10px;}

}

</style>