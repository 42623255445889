<template >
<div class="cont"> 
<Header/>


<div id="tips">


<div class="row padding">

<div class="col-md-3">
<div class="rightmenu">

<div class="tiphead">first year tips</div>
<hr>
<router-link to="/admission" class="tipnav_cont">
<div class="tipnav">Admission Requirments</div>
<div class="tipsubcontent">
<div class="tipcontents utme">UTME entry requirements</div>
<div class="tipcontents direct">Direct entry requirements</div>
</div>
</router-link>

<router-link to="/Registration" class="tipnav_cont">
<div class="tipnav">Registration and matriculation</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Registration period</div>
<div class="tipcontents direct">Registration procedure</div>
</div>
</router-link>


<router-link to="/grading_system" class="tipnav_cont">
<div class="tipnav">Grading System</div>
<div class="tipsubcontent">
<div class="tipcontents utme">Grading system breakdown</div>
<div class="tipcontents direct">Score ranking</div>
<div class="tipcontents period">Period of formal study</div>
</div>
</router-link>

<router-link to="/Examination_tips" class="tipnav_cont">
<div class="tipnav">Examination offenses and discipline</div>
</router-link>

<router-link to="/career_opportunities" class="tipnav_cont">
<div class="tipnav">Career opportunities for chemical engineer</div>
</router-link>

<div class="tipnav_cont">
<div class="tipnav">Result calculation tips</div>
</div>


<!-- end -->
</div>
</div>

<div class="col-md-9">

<div class="mainhead">GRADING SYSTEM</div>
<hr>


<!-- cont1 -->
<div class="tipcont1"> 
 <div class="contents">
<span class="content_title UTME_contnet">Grading system breakdown</span>
The examination of students is earned out on continuous assessment in addition to examination at die end of each semester.
The breakdown of the grading system is given as follows:
<br>
<table class="table table-stripped" border="1px">
<tr><td>Quizzes, Tutorial, Assignments, test etc</td><td>30%</td></tr>
<tr><td>End of Semester Examinationc</td><td>70%</td></tr>
</table>

A student will only be qualified to write the end of semester examination if he/she has met a minimum of 75% Lecture attendance and has done his/her class work, assignments and tests for continuous assessment component of the final grade on the subject. A student who fails to have a continuous assessment record will automatically have a Failure (F) grade for the subject. 
Student will be graded based on the score ranking shown in the table below:
 </div>
<div class="contents">
<span class="content_title direct_contnet">Score Ranking</span>
Direct entry admission is based on a combination of Ordinary Level results
With the following qualifications:
<br>
<table class="table table-stripped" border="1px">
<tr><th>Score</th><th>Grade</th><th>Grade Point (GP)</th><th>Description</th></tr>
<tr><td>70-100</td><td>A</td><td>5</td><td>Excellent</td></tr>
<tr><td>60-69</td><td>B</td><td>4</td><td>Very Good</td></tr>
<tr><td>50-59</td><td>C</td><td>3</td><td>Good</td></tr>
<tr><td>45-49</td><td>D</td><td>2</td><td>Fair</td></tr>
<tr><td>40-44</td><td>E</td><td>1</td><td>Pass</td></tr>
<tr><td>39 and Below</td><td>F</td><td>0</td><td>Fail</td></tr>

</table>
</div>
</div>
<!-- cont1 end -->


</div>


</div>
</div>
<br>
<br>
<br>

<Resmenu />

<div class="footer row">
<Footer />
</div>

</div>




</template>


<script>

import Footer from '../plugins/Footer.vue'
import Header from '../plugins/header.vue'
import Resmenu from '../plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
name: 'Addmission',
components: {
Header,
Footer,
Resmenu

}, 
data() { 
return {
//balance:0,
}},
methods:{ 

},
//computed(){},
mounted(){
AOS.init()

}


}

</script>

<style scoped>

.none,.desknone{display: none;}
#cont{padding: 0px;margin-top: 0px;color: black;font-size: 18px;}
.sectionheader{color:#008a31;font-size: 22px;text-transform: uppercase;font-weight: bold;}
#tips{padding: 20px;padding-bottom: 0px;margin-top: 60px;}

.tiphead{font-size: 20px;text-transform: capitalize;}
.mainhead{font-size: 22px;}
.tipnav{font-size: 17px;cursor: pointer;}
.tipsubcontent{font-size: 16px;border-left: 1px solid rgba(0, 0, 0, 0.534);margin-left: 10px;padding-left: 5px;}
.tipcontents{margin-top: 0px;margin-bottom: 0px;cursor: pointer;padding: 10px;padding-top: 5px;}
/* .tipnav_cont:hover{background-color: rgba(247, 247, 247, 0.898);width: auto;} */
.tipnav_cont{margin-bottom: 20px;}
.content_title{text-transform: capitalize;font-weight: bold;display: block;margin-bottom: 5px;}
.tt{list-style-type: lower-roman;}
.contents{margin-top: 30px;text-align: justify;}

.router-link,a{color: black;}
.router-link-active{background-color: rgba(247, 247, 247, 0.898);width: auto;display: block;padding: 5px;}
.router-link-active .tipnav{font-weight: bold;}

.table,td{border:1px solid black;padding: 6px;}
.table{margin-top: 10px;margin-bottom: 10px;}


@media screen and (max-width:600px){
.resnone{display: none;}
.mainhead{font-size: 16px;margin-top: 20px;}

#cont{font-size: 15px;}
#tips{padding: 10px;padding-bottom: 0px;margin-top: 35px;}
.tiphead{font-size: 17px;text-transform: capitalize;}
.padding{padding: 10px;}

}

</style>