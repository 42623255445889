<template>
<div class="cont">
<Header />

<div class="About">

<div class="row write animate__animated animate__zoomIn">

<h4 class="sectionheader">About NSChE</h4>

The Nigeria Socieety of Chemical Engineering Students (NSChE) is a vibrant and dynamic organization that represents and unites chemical engineering 
students across the country. As a leading nigeria body, NSChE is committed to empowering its members by providing them with 
opportunities for academic, professional, and personal development in the field of chemical engineering.

NSChE serves as a platform for chemical engineering students from various universities and institutions to come together, share knowledge, exchange ideas, and collaborate on projects of mutual interest. 
The association fosters a sense of camaraderie and community among its members, creating a supportive network that extends beyond individual campuses</div>
<br>
<!-- ------------------------------------------------------------------------------------------------------------------------------------ -->
<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">Objectives</h4>
1. Academic Enrichment: NSChE is dedicated to enriching the academic experiences of its members. The association organizes workshops, seminars, 
and lectures delivered by experts in the field of chemical engineering. These events aim to supplement the students' formal education with practical insights and the latest industry trends.<br>

2. Professional Development: NSChE strives to equip its members with the skills and knowledge needed for successful careers in chemical engineering. The association offers career guidance, 
resume-building workshops, and internship opportunities to help students gain valuable real-world experience and enhance their employability.<br>

3. Research and Innovation: NSChE recognizes the significance of research in advancing the chemical engineering discipline. Through conferences, symposiums, and research competitions, 
the association promotes research activities among students, encouraging them to explore innovative solutions to global challenges.<br>

4. Industry Collaboration: NSChE actively engages with the chemical engineering industry, forging partnerships with companies and organizations. These collaborations facilitate industrial visits,
 guest lectures, and internships, enabling students to understand industry practices and foster connections with potential employers.<br>

5. Leadership and Soft Skills: The association emphasizes the development of leadership and soft skills among its members. NSChE conducts leadership training, team-building exercises, 
and communication workshops to nurture well-rounded individuals who can excel in both professional and personal spheres.<br>

6. Community Engagement: NSChE believes in giving back to society. The association organizes community service initiatives and environmental awareness campaigns to promote the responsible and 
ethical practice of chemical engineering for sustainable development.<br>

</div>


<div class="row write animate__animated animate__zoomIn">
<h4 class="sectionheadersub">Membership Benefits</h4>
Joining NSChE provides numerous benefits to chemical engineering students, including:

a. Networking: Opportunities to connect with peers, professionals, and alumni within the chemical engineering community.<br>

b. Learning Opportunities: Access to workshops, seminars, and industry visits that enhance academic and professional growth.<br>

c. Career Support: Guidance and resources to navigate career paths, internships, and job opportunities.<br>

d. Research Exposure: Platforms to present research findings, collaborate on projects, and publish papers.<br>

e. Leadership Experience: Opportunities to hold leadership positions within the association and develop essential leadership skills.<br>
</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------------ -->


<div class="row write animate__animated animate__zoomIn">

The nigeria Society of Chemical Engineering Students (NSChE) plays a pivotal role in shaping the future of chemical engineering in the country. By fostering a collaborative and supportive environment, NSChE empowers its members to become skilled professionals who can drive innovation, address societal challenges, and make a positive impact in the field of chemical engineering.
 As a united force, NSChE continues to inspire and uplift chemical engineering students, propelling them towards a successful and fulfilling career journey.
</div>


<Resmenu />

</div><!--- home end-->


<Footer />
</div>

</template>

<script>
//import $ from "jquery"

import Header from './plugins/header.vue'
import Footer from './plugins/Footer.vue'
import Resmenu from './plugins/rmenu.vue'


import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
name: 'HomePage',
components: {
Header,
Footer,
Resmenu

},
mounted() {AOS.init()}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
body{overflow: hidden;}
.sectionheader{color:#008a31;text-transform: uppercase;font-weight: bold;margin-bottom: 10px;margin-left: -10px;text-align: center;}
.sectionheadersub{color: black;text-transform: uppercase;font-weight: bold;margin-bottom: 10px;font-size: 17px;margin-left: -10px;}

.row{margin-bottom: 40px;font-size: 16px;text-align: justify;}

.About{padding: 50px;overflow: hidden;margin-bottom:50px ;margin-top: 40px;}
.main{font-size: 18px;color:white;text-align: justify;}



@media screen and (max-width:480px){
.home{padding: 20px;padding-bottom: 0px;margin-top: 120px;}
.sectionheader{margin-bottom: 20px;}
/* .main{font-size: 14px;text-align: justify;padding: 4px;} */
.About{padding: 20px;overflow: hidden;margin-bottom:20px ;margin-top: 40px;}
.row{font-size: 15px;text-align: justify;}

/* .write{padding: 10px;padding-top: 0px;font-size: 14px;text-align: LEFT;} */

}
</style>
