<template>
<div id="app">
<!--<Header />-->
<br>
<div class="contain">

<router-view></router-view>

</div>
<br>
<!--<Footer />-->

</div>
</template>

<script>
//import Header from './components/plugins/header.vue'   leave-active-class="animate__animated animate__fadeOut"
//import Footer from './components/Footer.vue'

export default {
  name: 'App',
   components: {
  //  Header,
  //  Footer
  }
}





</script>

<style scoped>
*{outline: none;}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  /* background-color: rgba(218, 218, 218, 0.186); */
  /* background-color: rgba(203, 203, 203, 0.186);background-size: cover; */
  /* background-color: #e3e3fc71; */
  background-color: white;
  padding: 0px;margin-top: 0px;
  overflow-x: hidden;
  padding-bottom: 0px;
}
@media screen and (max-width:480px){
/* #app,body,.cont{overflow-x: hidden;} */
}
</style>
