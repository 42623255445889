
import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'

import VueRouter from 'vue-router'
Vue.use(VueRouter)


//pages
import Home from './components/Home.vue'
import About from './components/About.vue'
import FAQ from './components/FAQ.vue'
import Contact from './components/Contact.vue'
// import Support from './components/Support.vue'
import Executives from './components/Excos.vue'
import admission from './components/helps/admission.vue'
import registration from './components/helps/registration.vue'
import grading from './components/helps/GradingSystem.vue'
import exam_tips from './components/helps/examination.vue'
import career from './components/helps/career.vue'
import Aabout from './components/AssociationAbout.vue'


//blog pages
// import BlogHome from './components/blog/blog_home.vue'



const routes =[

//user dashboard
{path:'/Home',name:'Home',component:Home},
{path:'/',name:'Home',component:Home},
{path:'/About',name:'About',component:About},
{path:'/FAQ',name:'FAQ',component:FAQ},
{path:'/Contact',name:'Contact',component:Contact},
// {path:'/Support',name:'Support',component:Support},
{path:'/Executives',name:'Executives',component:Executives},
{path:'/admission',name:'Help',component:admission},
{path:'/grading_system',name:'grading',component:grading},
{path:'/registration',name:'registration',component:registration},
{path:'/examination_tips',name:'exam_tips',component:exam_tips},
{path:'/career_opportunities',name:'career',component:career},
{path:'/about_association',name:'Aabout',component:Aabout},


// {path:'/NAOS_medium',component:BlogHome},



]
const router = new VueRouter({routes})



new Vue({
  router,
  render: h => h(App),
  }).$mount('#app')
