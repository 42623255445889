<template>
<div class="cont">
<Header />
<div class="faq">
<h4 class="sechead" data-aos="slide-up">Frequently Asked Questions</h4>

<!--Website cobntainer--->
<center>

<div class="faqcont row">


<!---general was the first div but i took it down-->
<div class="faq1 col-md-4" data-aos="slide-up">
<div class="question" data-aos="slide-up">General</div>
<div class="faqs">
<div class="faqshead head1">What is Chemical Engineering?</div>   
<div class="faqsbody body1">Chemical engineering is a multidisciplinary field that applies principles of chemistry, physics, and mathematics to design,
optimize, and operate processes that transform raw materials into useful products. It involves developing and implementing innovative solutions for
various industries, such as petrochemicals, pharmaceuticals, food processing, and environmental protection.
</div>   

</div>   



<div class="faqs" data-aos="slide-up">
<div class="faqshead head2">What programs does the Chemical Engineering Department offer?</div>   
<div class="faqsbody body2">The department offers undergraduate and graduate programs in chemical engineering. The undergraduate program leads to a 
Bachelor's degree in Chemical Engineering, while the graduate program offers Master's and Ph.D. degrees.</div>   
</div> 


<div class="faqs" data-aos="slide-up">
<div class="faqshead head3">How can I apply for admission to the Chemical Engineering Department at MOUAU?</div>   
<div class="faqsbody body3">To apply for admission, prospective students should follow the university's admission process, 
which typically involves filling out an online application form, submitting required documents, and meeting the specified admission criteria.
</div>   
</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head4">What are the career prospects for chemical engineering graduates?</div>   
<div class="faqsbody body4">Chemical engineering graduates have a wide range of career opportunities in industries such as manufacturing,
energy, pharmaceuticals, environmental protection, and research and development. They can work as process engineers, design engineers, 
research scientists, environmental consultants, and more.

</div>   
</div>



</div>


<div class="faq2 col-md-4">
<div class="question" data-aos="slide-up">Research and Internship (IT)</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head5">Are there research opportunities for undergraduate students in the department?</div>   
<div class="faqsbody body5">Yes, the department encourages undergraduate students to engage in research projects under the guidance of faculty members. 
Research opportunities help students gain practical experience and contribute to the advancement of knowledge.</div>   
</div>
<div class="faqs" data-aos="slide-up">
<div class="faqshead head6">Does the Chemical Engineering Department provide industrial training and internships?</div>   
<div class="faqsbody body6">Yes, the department facilitates industrial training and internships for students to gain hands-on experience in real-world engineering environments.
These opportunities help students develop industry-specific skills and build professional networks.
</div>   
</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head7">What research areas are focused on within the department?</div>   
<div class="faqsbody body7">The department's faculty conducts research in diverse areas such as process optimization, renewable energy, nanotechnology, environmental engineering, materials science, and sustainable manufacturing.

</div>   
</div>

<!---another set-->

<div class="question" data-aos="slide-up">Association (NSChE)</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head11">Are there student organizations related to chemical engineering?</div>   
<div class="faqsbody body11">Yes, the department has engineering clubs and societies where students can participate in extracurricular activities, competitions, and social events.
</div>   
</div>
<div class="faqs" data-aos="slide-up">
<div class="faqshead head12">How can I pay my NSChE dues?</div>   
<div class="faqsbody body12">Bank - Access Bank<br>Account Name - NSChE MOUAU<br> Account Number - 1469609555.</div>   
</div>

</div>

<div class="faq3 col-md-4">
<div class="question" data-aos="slide-up">Laboratory</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head8">How is the laboratory infrastructure in the Chemical Engineering Department?</div>   
<div class="faqsbody body8">The department is well-equipped with modern laboratories that cater to various aspects of chemical engineering, including process simulation, materials testing, and environmental analysis.
</div>   
</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head9">How does the department foster a culture of safety in laboratories and research facilities?</div>   
<div class="faqsbody body9">The department places a strong emphasis on safety training and protocols in laboratories and research facilities to ensure the well-being of students and staff.
</div>   
</div>


<div class="faqs" data-aos="slide-up">
<div class="faqshead head10">What facilities are available for students to work on design projects and prototypes?</div>   
<div class="faqsbody body10">The department has designated spaces and resources for students to work on design projects and build prototypes, fostering creativity and innovation.
</div>   
</div>

<!--another set-->
<div class="question" data-aos="slide-up">Contact</div>

<div class="faqs" data-aos="slide-up">
<div class="faqshead head13">How can I contact Chemical Engineering at MOUAU?</div>   
<div class="faqsbody body13">You can contact the chemical engineering at MOUAU through its official website, social media handles, or by reaching out to chemical engineering department.</div>   
</div>

</div>


</div>
</center>

</div>
<br><br><br>
<Resmenu />

<Footer />
</div>
</template>

<script>
import Header from './plugins/header.vue'
import Footer from './plugins/Footer.vue'
import Resmenu from './plugins/rmenu.vue'

import $ from "jquery"

export default {
name: 'FAQPage',
components: {
Header,
Footer,
Resmenu
},
mounted(){

$(".head1").click(function(){$(".body1").toggle()})
$(".head2").click(function(){$(".body2").toggle()})
$(".head3").click(function(){$(".body3").toggle()})
$(".head4").click(function(){$(".body4").toggle()})
$(".head5").click(function(){$(".body5").toggle()})
$(".head6").click(function(){$(".body6").toggle()})
$(".head7").click(function(){$(".body7").toggle()})
$(".head8").click(function(){$(".body8").toggle()})
$(".head9").click(function(){$(".body9").toggle()})
$(".head10").click(function(){$(".body10").toggle()})
$(".head11").click(function(){$(".body11").toggle()})
$(".head12").click(function(){$(".body12").toggle()})
$(".head13").click(function(){$(".body13").toggle()})
$(".head14").click(function(){$(".body14").toggle()})
$(".head15").click(function(){$(".body15").toggle()})
$(".head16").click(function(){$(".body16").toggle()})
$(".head17").click(function(){$(".body17").toggle()})
$(".head18").click(function(){$(".body18").toggle()})
$(".head19").click(function(){$(".body19").toggle()})
$(".head20").click(function(){$(".body20").toggle()})

}
}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only rgb(37, 37, 204)-->
<style scoped>
.faq{padding: 20px;margin-top: 20px;color: #464646;font-size: 18px;}
.sechead{margin-bottom: 50px;margin-top: 70px;color:#008a31;text-align: center;text-transform: uppercase;font-weight: bold;}

/*########*/
.faqs{width: 80%;border-radius: 2px;background-color:white;
background-clip:border-box;border-radius:.25rem;text-align: left;margin-bottom: 20px;border-radius: 5px;}
.faqshead{color:black;font-size: 16px;height: auto;background-color:white;border-radius: 5px;cursor: pointer;
padding: 10px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); ;}
/*.faqshead:hover{color:#fc5b3f;}*/

.faqsbody{padding: 10px;display: none;font-size: 14px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);}
.question{font-size: 22px;color: #008a31;margin-bottom: 10px;text-align: left;margin-left: 10%;font-weight: bold;}

@media screen and (max-width:480px){
.sechead{font-size: 17px;margin-top: 30px;margin-bottom: 10px;}
.faqs{width: 96%; margin-left: 2%;}
.faq{padding: 20px;margin-top: 10px;font-size: 15px;}
.question{font-size: 16px;margin-left: 2%;margin-top: 30px;}
.abouthead{font-size: 16px;margin-bottom: 20px;}
.forres{display: block;}

}

</style>
