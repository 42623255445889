<template>

<div class="resmenu animate__animated animate__slideInLeft none">
 <!-- <span class="limgcont">
   <router-link to="/"> <span class="site_name">NAOS WORLDWIDE</span> </router-link>
 <img src="../../assets/x.png" alt="" class="back" v-on:click="hidemenu()"></span>
 -->

<div class="mmenucont">
<!-- <hr> -->

<router-link to="/Home" class="navs home">Home</router-link>
<router-link to="admission" class="navs">Admissions</router-link>
<div class="navs asso_nav"  @click="showdropdown1()">Association<span class="drop drop1">^</span></div>
<!-- absolute -->
<div class="subnav_res subnav_res1">
<router-link to="/Executives" class="snavs">Executives</router-link>
<router-link to="about_association" class="snavs">About Us</router-link>
<!-- <router-link to="About_Association" class="snavs">Support Us</router-link> -->
</div>

<router-link to="About" class="navs">About Us</router-link>
<div class="navs asso_nav"  @click="showdropdown2()">Guidelines<span class="drop drop2">^</span></div>
<!-- absolute -->
<div class="subnav_res subnav_res2">
<router-link to="/admission" class="snavs">Admission</router-link>
<router-link to="/Registration" class="snavs">Registration</router-link>
<router-link to="/grading_system" class="snavs">Grading System</router-link>
<router-link to="/Examination_tips" class="snavs">Examination Tips</router-link>
<router-link to="/career_opportunities" class="snavs">Career Opportunities</router-link>
</div>

<!-- <router-link to="" class="navs">Blog</router-link> -->
<hr>
<router-link to="/Contact" class="navs">Contact Us</router-link>
<router-link to="/FAQ" class="navs">FAQ</router-link>

<hr>
<div class="snavhead">Resources</div>
<a class="navs" href="https://nsche-bot.netlify.app" target="_blank">Chat with us</a>
<a class="navs" href="https://timblee.netlify.app" target="_blank">Result Calculator</a>

 </div>

<!-- <router-link to="/" class="action_btn rm link text-light">Login</router-link> -->
<br>

<!-- <div class="powered">Powered by Echelon Tech LLC</div> -->
</div>
</template>


<script>
import $ from "jquery"

export default {
  name: 'Resmenu',
  components: {
  },
  methods:{
showsubnav: function(){
$(".subnav").toggle(500)
},
hidemenu: function(){
$(".resmenu,.sub_navs").hide(1000)
//.removeClass("animate__slideInLeft").addClass("animate__slideOutLeft")
},
showdropdown1: function(){
$(".subnav_res1").slideToggle(1000)
$(".subnav_res2").hide(100)
},
showdropdown2: function(){
$(".subnav_res2").slideToggle(1000)
$(".subnav_res1").hide(100)

}
 },
  mounted() {
  $(".navs,.sub_navs").click(function(){$(".subnav").hide(500)})
  $(document).scroll(function(){$(".subnav").hide(500)})

}
}
</script>

<style scoped>
.none{display: none;}

@media screen and (max-width:480px){
.navs{margin-top:5px;margin-bottom: 5px;padding: 10px;padding-left: 20px;font-size: 17px;
text-decoration: none;color: black;display: block;}

.resmenu{padding-bottom: 20px;;width: 60%;display: none;z-index: 999;top: 0px;margin-top: 60px;border-radius: 1%;
padding-top: 0px;color: black;background-color:rgba(255, 255, 255, 0.948);position: fixed;left:0%;height: 100%;backdrop-filter: blur(20px);
}
.mmenucont{margin-top: 0px;}
.rm{position: relative;background-color: #008a31;
padding: 5px;padding-top: 7px;margin-top: 30px;font-size: 14px;width: 60%;text-align: center;
margin-left: 5%;margin-bottom: 20px;display: block;border-radius: 4px;font-weight: bold;}

/* .router-link-active{color: white;font-weight: bold;background-color:  #008a31;} */

.back{width:30px;height:30px;margin-top: -2px;float: right;margin-right: 0px;border: 2px solid  #008a31;padding: 4px;}

a,a:visited,.link:hover{text-decoration: none;}

hr{background-color:  #008a31;border: 1px solid  #008a31;}

.site_name{font-size: 16px;font-weight: bold;margin-top: 0px;margin-right: 0px;float: left;color:  #008a31;}
.powered{font-size: 10px;position: absolute;bottom: 0px;font-weight: bold;text-transform: uppercase;margin-left: 20px;}

.drop{transform:rotate(180deg);display: inline-block;margin-top: -54px;margin-left: 2px;font-size: 26px;}
.asso_nav:hover{color:  #008a31;font-weight: bold;}

.subnav_res{background-color: black;padding: 15px;display: none;;}
/* rgba(80, 80, 80, 0.865) */
.snavs{font-size: 14px;padding-left: 8px;display: block;color: white;margin-bottom: 10px;}
.router-link-active{color:  #008a31;font-weight: bold;}

.snavhead{margin-left: 20px;color:rgba(0, 0, 0, 0.78);font-size: 20px;}

}

</style>