<template>
<div class="head">
<div class="row">
<div class="col-md-4"> 
<span class="mimgcont desknone"> 
<img src="../../assets/menu.png" alt="" class="menuimg img1" v-on:click="showmenu()">
<img src="../../assets/x.png" alt="" class="back none" v-on:click="hidemenu()">
</span>
   <router-link to="/"> <span class="site_name resnone">NSCHE MOUAU</span> </router-link>

<router-link to="/" class=""><img src="../../assets/nschelogo.jpg" alt="" class="naoslogo desknone"></router-link>

  </div>
<div class="col-md-6 resnone">
<div class="navCont">
<router-link to="/Home" class="navs home">Home</router-link>
<router-link to="/admission" class="navs">Admission</router-link>
<div class="navs asso_nav"  @click="showdropdown1()">Association <b class="drop drop1">^</b></div>
<!-- <b class="drop">^</b> -->
<router-link to="About" class="navs">About us</router-link>
<!-- <router-link to="/Executives" class="navs">Executives</router-link> -->
<router-link to="/Contact" class="navs">Contact us</router-link>
<div class="navs asso_nav"  @click="showdropdown2()">Guidelines <b class="drop drop1">^</b> </div>



</div>
</div>

<div class="col-md-2 resnone">
<div class="login" @click="showlogin()">Login <img src="../../assets/user.png" alt="" class="profilee_img"></div>

<div class="fixedform none">
<div class="inputcont">
<div class="label">Name</div>
<input type="text" placeholder="Ukonu George" class="small_input">
</div>

<div class="inputcont">
<div class="label">Password</div>
<input type="text" placeholder="*********" class="small_input">
</div>
<br>

<button class="submitbtn">Submit</button>

<span class="dont">Don't have an account yet? <b>Sign up</b></span>
</div>

</div>

</div>
<!-- absolute -->
<!-- subnav1 -->
<div class="subnav_head1 subnav_head">
<router-link to="/Executives" class="snavs">Executives</router-link>
<router-link to="/about_association" class="snavs">About Us</router-link>
<!-- <router-link to="/Support" class="snavs">Support Us</router-link> -->
</div>


<!-- subnav2 -->
<div class="subnav_head2 subnav_head">
<router-link to="/admission" class="snavs">Admission</router-link>
<router-link to="/Registration" class="snavs">Registration</router-link>
<router-link to="/grading_system" class="snavs">Grading System</router-link>
<router-link to="/Examination_tips" class="snavs">Examination Tips</router-link>
<router-link to="/career_opportunities" class="snavs">Career Opportunities</router-link>
</div>



</div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

import $ from "jquery"


export default {
  name: 'Header',
  methods:{ 
showmenu: function(){
$(".resmenu,.back").show(0)
$(".menuimg").hide(0)

},hidemenu: function(){
$(".resmenu,.back").hide(0)
$(".menuimg").show(0)
},

showdropdown1: function(){
$(".subnav_head1").slideToggle(1000)
$(".subnav_head2,.fixedform").hide(1000)
},
showdropdown2: function(){
$(".subnav_head2").slideToggle(1000)
$(".subnav_head1,.fixedform").hide(1000)
},
showlogin: function(){
$(".fixedform").slideToggle(1000)
$(".subnav_head2,.subnav_head1").hide(1000)

}



  }
,
mounted() {
AOS.init()
}

}


</script>

<style scoped>
/* .logoo{width: 280px;height: 90px;margin-top: -30px;} */


/* .navs{margin-left: 10px;margin-right: 10px;color:#a8a899;font-size: 15px;text-decoration: none;}
.home{color: white;}
.navs:hover{color: white;font-weight: bold;} */

.none{display: none;}
.head{padding:30px 40px 0px 40px;background-color: white;position: fixed;height: 90px;width: 100%;z-index: 999;top: 0px;
box-shadow: 2px 2px 2px 2px  #008a3016;} /* top right botrgba(0, 138, 48, 0.403)t     box-shadow:0px 1px 0px 0px rgba(255, 255, 255, 0.801);*/
/* rgba(199, 199, 199, 0.805) */
.navCont{display: flex;}
.navs{margin-right: 20px;font-size: 15px;color: black;}
.site_name{font-size: 22px;font-weight: bold;margin-top: -9px;display: block;color: #008a31;text-transform: uppercase;}

a{text-decoration: none;}

.desknone,.none{display: none;}
.login{width: auto;margin-left: 0px;text-align: center;padding: 5px;margin-top: -5px;font-size: 15px;border-radius: 5px;
background-color: #008a31;color: white;transition: background-color 4s;
transition-timing-function: ease;}

.login:hover{background-color: black;}

.subnav_head{background-color: rgba(255, 255, 255, 0.943);position: fixed;top: 55px;padding: 15px;margin-left: 40%;border-bottom-left-radius: 1px;display: none;padding-top: 0px;
border-bottom-right-radius: 1px;border-bottom: 4px solid green;width: 150px;padding-left: 37px;transition: display 4s;
transition-timing-function: ease;}

.subnav_head2{padding: 15px;padding-left: 27px;margin-left: 61%;width: 160px;padding-top: 0px;}
.snavs{font-size: 14px;padding-left: 4px;display: block;color: black;margin-top: 10px;}


.drop{transform:rotate(180deg);display: inline-block;margin-top: -73px;margin-left: -3px;}
.asso_nav:hover{color: #008a31;font-weight: bold;}

.profilee_img{width: 20px;height: 20px; margin: 5px;margin-left: 10px;}


.fixedform{position: absolute;background-color: rgba(255, 255, 255, 0.989);right: 0.5%;
margin-top: 18px;padding: 14px;border-bottom-left-radius: 8px;padding: 10px;
border-bottom-right-radius: 8px;height: auto;width: 250px;padding-top: 2px;
backdrop-filter: blur(80px);box-shadow: 2px 2px 2px 2px  #008a3016;
}
.fixedform:before{
content:"";
position: absolute;
left: 0;
top: 0%;
width: 0;
height: 0;
border: 12px solid transparent;
border-bottom-color: green;
margin-top: -26px;
margin-left: 45%;}

.small_input{background-color: transparent;width: 100%;border: 1px solid #008a31;height: 30px;border-radius: 4px;}
.label{font-size: 14px;}
.inputcont{margin-bottom: 10px;}
.submitbtn{width: 60%;background-color: #008a31;height: 30px;border-radius: 4px;margin-left: 20%;border: none; color: white;}
input{color: black;}
.dont{display: block;font-size: 12px;text-align: center;margin-top: 10px;}



.router-link-active{color: #008a31;font-weight: bold;} 


@media screen and (max-width:480px){
.head{padding:5px 10px 0px 10px;height: 60px;padding-top: 10px;}
.resnone{display: none;}
.site_name{font-size: 22px;font-weight: bold;margin-top: 0px;}
.mimgcont{margin-right: 0px;float: left;display: inline-block;border-radius: 2%;}
.menuimg{width: 35px;height: 35px;border: 2px solid #008a31;padding: 6px;border-radius: 0px;background-color:#008a31;border-radius: 2px;}
.back{width:35px;height:35px;margin-top: 0px;margin-right: 0px;border: 2px solid #008a31;padding: 6px;background-color:#008a31 ;border-radius: 2px;}
.naoslogo{width: 45px;height: 45px;padding: 4px;float: right;display: inline-block;margin-top: 0px;margin-right: -8px;}
}
</style>
