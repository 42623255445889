var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont"},[_c('Header'),_c('div',{staticClass:"About"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('Resmenu')],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheader"},[_vm._v("About Us")]),_vm._v(" Considering the global competition for jobs, the need for a graduate to stand out from the crowd cannot be over emphasized. Chemical Engineering is a profession that provides solution to problems. Chemical Engineering in general is concerned with industrial processing of natural raw materials into finished products of enhanced industrial and commercial values through the application of basic science. It is one of the most broadly based engineering disciplines, creating high demand for Chemical Engineers across a variety of industries and professions. Chemical Engineering is concerned with identifying suitable raw materials and processes for their exploitation, development and conversion into immediate and useful products through application of basic science. Our goal is to provide the adequate educational training and skills necessary for understanding, planning, designing, operating and maintaining the various processes and operations involved in the modern chemical industry in our students. Students at this Department of Chemical Engineering will be equipped to take lead responsibilities in the manufacturing, exploration and energy generation sector. Final year undergraduate students in this department will work on relevant real life problems as projects and such projects will be tailored to specific needs in the society. The purpose for this training approach is to produce graduates who will be problem solvers and consequently create jobs instead of quailing jobs.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheadersub"},[_vm._v("Our Vision")]),_vm._v(" Our vision is to establish strong collaborations with universities within the country and the Western World for the pin pose of exchanging our research findings and thereby propagating the name of our great university, MOUAU ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheadersub"},[_vm._v("Our Mission")]),_vm._v(" The mission of the Department of Chemical Engineering, MOUAU is to produce world class Chemical Engineers who will be solution providers in their respective organizations. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheadersub"},[_vm._v("Our Goals")]),_vm._v(" our main gola is to provide the adequate educational training and skills necessary for understanding, planning, designing, operating and maintaining the various processes and operations involved in the modern chemical industry in our students. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheadersub"},[_vm._v("AIMS AND OBJECTIVES")]),_vm._v(" The aim of establishing the Department of Chemical Engineering is to bridge the gap between research in the academia and the industry. There are so many challenges facing the industry and the technical know-how to face the challenges is paramount. We are out to provide the technical know-how and to chart the course for an industrial revolution. The objective of this newly established department is not only to train students for conventional jobs but also to adequately equip students for the real world outside the university in order for them to be real innovators, inventors and entrepreneurs. The graduates from the Department of Chemical Engineering, MOUAU, should be able to compete with other graduates of Chemical Engineering anywhere in the world. The department will take advantage of the collaborations between MOUAU and other universities in Europe and America to further train the students. Students will be encouraged to get relevant experience in related companies/ institutions. Internigeria and result-oriented researches will he carried out by the undergraduate students of this department. Every 4th year student will have a mentor in a university/ research instution in preparation for the final Bachelor Thesis. All theses to be taken at the department must be result driven and tailored to the specific needs of the society. As the Head of the Chemical Engineering Department, I will make sure that the graduates from this department are highly sort after due to the high quality of training they will undergo. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row write animate__animated animate__zoomIn"},[_c('h4',{staticClass:"sectionheader"},[_vm._v("A Brief History About Chemical EngineeriNg MOUAU")]),_vm._v(" Michael Okpara University of Agriculture, Umudike was established in May, 1993, by Edict No. 48 of the Federal Government of Nigeria. Although the school was established with one of its primary focus being to provide quality trainings for students for the purpose of agricultural development and improved techniques for better agricultural yields for I he sustenance of the nation; it was soon obvious that this will be impossible without a complementary framework. Consequently, Dr. O. Onuba in 2001/2002 academic session, introduced the College of Engineering and Engineering Technology into the University's existing colligate system with the establishment of the Department of Agricultural Engineering followed by the Department of Civil Engineering. Mechanical Engineering and Electrical/Electronics Engineering in the 2003/2004 academic session. Chemical Engineering was introduced in the 2011/2012 academic session. All of these departments run a 5-year degree programme which culminates into the award of the Bachelor of Engineering (B.Eng) degree. Prof. Adekunle Kayode was the first (Head of Department of Chemical Department MOUAU). ")])
}]

export { render, staticRenderFns }